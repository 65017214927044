import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Page } from './Screen';
import Title from './Title';
import Image from './Image';
import image from './assets/feedback.svg';

function Feedback({ data, bgImage }) {
  return (
    <Page bgImage={bgImage}>
      <Typography>有</Typography>
      <Title>{data.me.rewind.feedbackCount}</Title>
      <Typography>位聽眾留言與回饋給您</Typography>
      <Image src={image} />
      <Typography variant="body2">感謝聽眾這一年的支持！</Typography>
    </Page>
  );
}

export default Feedback;
