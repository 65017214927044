import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Page } from './Screen';
import Title from './Title';
import Image from './Image';
import image from './assets/top-hour.svg';

function TopHour({ data, bgImage }) {
  const { title, description } = React.useMemo(() => {
    const d = data.me.analyticData.aggregate.hourlyUniqueStarts;
    let offsetData = d;
    const timezoneOffsetHour = Math.floor(new Date().getTimezoneOffset() / 60);
    if (timezoneOffsetHour > 0) {
      offsetData = [
        ...d.slice(timezoneOffsetHour),
        ...d.slice(0, timezoneOffsetHour),
      ];
    } else if (timezoneOffsetHour < 0) {
      offsetData = [
        ...d.slice(d.length + timezoneOffsetHour),
        ...d.slice(0, d.length + timezoneOffsetHour),
      ];
    }
    let topHour = 0;
    let maxValue = 0;
    offsetData.forEach((d, i) => {
      if (d > maxValue) {
        maxValue = d;
        topHour = i;
      }
    });
    const day = Math.floor(topHour / 24);
    const hour = topHour % 24;

    const items = [
      ['假日深夜', '躲在被窩，抓緊週末最後的放鬆時光'],
      ['假日早上', '在週末早起，這群肯定是天選之人'],
      ['假日下午', '出門走走，讓聲音充滿城市咖啡廳'],
      ['假日晚上', '誰說夜晚只有酒趴，你的聲音也讓人微醺'],
      ['平日深夜', '不愧是夜貓族最愛的深夜節目（笑～）'],
      ['平日早上', '陪伴通勤仔，打通全身任督二脈'],
      ['平日下午', '戴起耳機，躲在茶水間佐上雞排'],
      ['平日晚上', '是去健身房呢？還是在家爽躺沙發？'],
    ];
    const index = (day === 0 || day === 6 ? 0 : 4) + Math.floor(hour / 6);
    return {
      title: items[index][0],
      description: items[index][1],
    };
  }, [data]);

  return (
    <Page bgImage={bgImage}>
      <Typography>你的聽眾最常在</Typography>
      <Title>{title}</Title>
      <Typography>聽你的節目</Typography>
      <Image src={image} />
      <Typography variant="body2">{description}</Typography>
    </Page>
  );
}

export default TopHour;
