import React from 'react';
import cx from 'classnames';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  image: {},
}));

function Image({ className, alt, ...props }) {
  const classes = useStyles();
  return <img alt={alt} className={cx(className, classes.image)} {...props} />;
}

export default Image;
