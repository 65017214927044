import React from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/styles';
import Menu from '@material-ui/core/Menu';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ArrowDown from '@material-ui/icons/KeyboardArrowDown';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AuthContext from '../Auth/context';
import { useGetMe } from '../../gql/hooks/user';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
    padding: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
}));

function AccountMenu({ open, onOpen, onClose }) {
  const classes = useStyles();
  const anchorRef = React.useRef();
  const auth = React.useContext(AuthContext);
  const { loading, data } = useGetMe();
  const location = useLocation();
  const intl = useIntl();

  const handleLogoutClick = React.useCallback(() => {
    auth.handleLogout();
    onClose();
  }, [auth, onClose]);

  if (loading) {
    return null;
  }

  return (
    <React.Fragment>
      <div ref={anchorRef} className={classes.container} onClick={onOpen}>
        <AccountCircleIcon />
        <ArrowDown fontSize="small" />
      </div>
      <Menu
        anchorEl={anchorRef.current}
        open={open}
        onClose={onClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {data && data.me ? (
          <List dense>
            <ListItem button component={RouterLink} to="/user/me">
              <ListItemAvatar>
                <Avatar variant="rounded" src={data.me.avatar} />
              </ListItemAvatar>
              <ListItemText
                primary={data.me.name}
                secondary={data.me.account.email}
              />
            </ListItem>
            <ListItem button onClick={handleLogoutClick}>
              <ListItemText
                primary={intl.formatMessage({ id: 'auth.logout' })}
              />
            </ListItem>
          </List>
        ) : (
          <List dense>
            <ListItem
              button
              component={RouterLink}
              to={{
                pathname: '/login',
                state: { background: location },
              }}
            >
              <ListItemText
                primary={intl.formatMessage({ id: 'auth.login' })}
              />
            </ListItem>
          </List>
        )}
      </Menu>
    </React.Fragment>
  );
}

export default AccountMenu;
