import gql from 'graphql-tag';
import { ShowInfoFragment, EpisodeFragment } from './fragments';

export const SearchEpisode = gql`
  query SearchEpisode(
    $showId: ID
    $playlistId: ID
    $queryString: String
    $first: Int
    $after: String
  ) {
    playerEpisodeFind(
      showId: $showId
      playlistId: $playlistId
      queryString: $queryString
      take: $first
      after: $after
    ) {
      ...EpisodeFragment
    }
  }
  ${EpisodeFragment}
`;

export const GetEpisodeDetail = gql`
  query GetEpisodeDetail($episodeId: ID!) {
    playerEpisodeFindOne(episodeId: $episodeId) {
      id
      title
      audioUrl
      imageUrl
      createdAt
      duration
      description
      show {
        ...ShowInfoFragment
      }
      tags
      distributions {
        status
        platformType
        platformId
        platformUrl
      }
    }
  }
  ${ShowInfoFragment}
`;
