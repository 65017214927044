import { useQuery } from '@apollo/react-hooks';
import { GetEpisodeDetail } from '../v2/episode';
import {
  transformArray,
  transformComment,
  transformEpisode,
  transformShow,
} from '../transform';
import usePagination from '../../hooks/usePagination';
import { PlayerCommentFind } from '../v2/comment';

export function useGetEpisodeDetail(episodeId) {
  const { data, ...args } = useQuery(GetEpisodeDetail, {
    variables: { episodeId },
  });

  if (!data) {
    return args;
  }

  return {
    data: {
      me: transformShow(data.studioShowFindOne),
      feed: transformEpisode(data.playerEpisodeFindOne),
    },
    ...args,
  };
}

export function useGetEpisodeComments(episodeId) {
  const { data, ...args } = usePagination({
    dataKey: 'playerCommentFind',
    query: PlayerCommentFind,
    variables: { id: episodeId, type: 'episode' },
    ssr: false,
  });

  if (!data) {
    return args;
  }

  return {
    data: {
      comments: transformArray(data.playerCommentFind, transformComment),
    },
    ...args,
  };
}

export function useGetEpisodeData(episodeId) {
  const { loading: episodeLoading, data: episodeData } =
    useGetEpisodeDetail(episodeId);
  const {
    loading: commentsLoading,
    data: commentsData,
    hasMore,
    loadMore,
  } = useGetEpisodeComments(episodeId);

  return {
    loading: episodeLoading,
    commentsLoading,
    data: {
      ...episodeData,
      ...commentsData,
    },
    hasMore,
    loadMore,
  };
}
