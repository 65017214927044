import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Page } from './Screen';
import Title from './Title';
import Image from './Image';
import image from './assets/country.svg';

function Country({ data, bgImage }) {
  const { country } = data.me.analyticData.aggregate;
  return (
    <Page bgImage={bgImage}>
      <Typography>你的聽眾來自</Typography>
      <Title>{country.length}</Title>
      <Typography>個國家</Typography>
      <Image src={image} />
      <Typography>新的一年繼續加油，</Typography>
      <Typography>世界的每個角落都有機會聽到你的節目！</Typography>
    </Page>
  );
}

export default Country;
