import React from 'react';
import { colors } from '../constants';

function Logo({ color, backgroundColor, ...props }) {
  return (
    <svg width="70px" height="70px" viewBox="0 0 70 70" {...props}>
      <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <circle
          fill={backgroundColor || colors.tomato}
          cx={35}
          cy={35}
          r={34}
        />
        <g fill={color || '#fff'}>
          <path
            d="M37.683 12.059s-2.387-2.412-10.74-2.412H18.59c-2.91 0-5.516-1.318-7.266-3.395l-.21-.256A9.664 9.664 0 019.045 0h19.093c5.272 0 9.546 4.32 9.546 9.647zM0 22.46c0-5.329 4.274-9.648 9.546-9.648H28.64c0 2.386-.86 4.569-2.28 6.253-1.75 2.076-4.355 3.394-7.266 3.394H10.74c-8.075 0-10.574 2.254-10.732 2.404L0 24.87zM4.522 25.624c-2.497 0-4.522 2.194-4.522 4.9 0 2.705 2.025 4.898 4.522 4.898 2.497 0 4.522-2.193 4.522-4.899 0-2.705-2.025-4.899-4.522-4.899"
            transform="translate(16 17)"
          />
        </g>
      </g>
    </svg>
  );
}

export default Logo;
