import React from 'react';
import cx from 'classnames';
import { makeStyles } from '@material-ui/styles';
import LazyImage from './LazyImage';
import { colorPalette } from '../constants';

export function getGradientColorIndex(createdAt = 0) {
  const date = new Date(createdAt);
  const seconds = date.getSeconds();
  const minutes = date.getMinutes();
  const hours = date.getHours();
  const n = (seconds + minutes + hours) % 6;
  return n;
}

const styles = {};

for (let i = 0; i < 6; i++) {
  styles[i] = {
    background: `linear-gradient(135deg, ${colorPalette[i][0]}, ${colorPalette[i][1]})`,
  };
}

const useStyles = makeStyles(styles);

function AlbumCover({ style, className, imageUrl, createdAt }) {
  const classes = useStyles();
  const gradientColorIndex = getGradientColorIndex(createdAt);

  return imageUrl ? (
    <LazyImage style={style} className={className} src={imageUrl} alt="cover" />
  ) : (
    <div style={style} className={cx(className, classes[gradientColorIndex])} />
  );
}

export default AlbumCover;
