import config from '../config';

const platformTitles = {
  rss: 'RSS Feed',
  firstory: 'Firstory',
  spotify: 'Spotify',
  apple: 'Apple Podcast',
  google: 'Google Podcast',
  pocketcast: 'Pocket Casts',
  kkbox: 'KKBOX',
  soundon: 'SoundOn Player',
};

const platformStatusKeys = {
  spotify: 'spotifyStatus',
  apple: 'applePodcastStatus',
  google: 'googlePodcastStatus',
  pocketcast: 'pocketcastStatus',
  kkbox: 'kkboxStatus',
  soundon: 'soundonStatus',
};

const platformUrlStartsWith = {
  rss: `${config.url}/rss/user`,
  firstory: `${config.url}/user`,
  spotify: 'https://open.spotify.com',
  apple: 'https://podcasts.apple.com',
  google: 'https://podcasts.google.com',
  pocketcast: 'https://pca.st',
  kkbox: 'https://podcast.kkbox.com/channel',
  soundon: 'https://player.soundon.fm',
};

const platformColors = {
  kkbox: '#09CEF6',
  spotify: '#1DB954',
  apple: '#B150E2',
  google: '#ffffff',
};

export function getPlatformStatus(platform, me) {
  if (platform === 'rss' || platform === 'firstory') {
    return 'ACTIVE';
  }
  if (!me || !me.podcastDistribution) {
    return null;
  }
  return me.podcastDistribution[platformStatusKeys[platform]];
}

export function getPlatformTitle(platform) {
  return platformTitles[platform] || 'Podcast';
}

export function getPlatformUrl(platform, me, type) {
  if (platform === 'rss') {
    return me.importRssUrl || `${config.url}/rss/user/${me.id}`;
  }
  if (platform === 'firstory') {
    const prefix = type === 'RECORD' ? 'story' : 'user';
    return `${config.url}/${prefix}/${me.urlSlug || me.id}`;
  }
  if (!me.podcastDistribution) {
    return '';
  }
  const status = getPlatformStatus(platform, me);
  if (status !== 'ACTIVE') {
    return '';
  }
  if (platform === 'spotify' && me.podcastDistribution.spotifyId) {
    const prefix = type === 'RECORD' ? 'episode' : 'show';
    return `https://open.spotify.com/${prefix}/${me.podcastDistribution.spotifyId}`;
  }
  if (platform === 'apple') {
    return me.podcastDistribution.applePodcastUrl || '';
  }
  if (platform === 'google') {
    return me.podcastDistribution.googlePodcastUrl || '';
  }
  if (platform === 'pocketcast') {
    return me.podcastDistribution.pocketcastUrl || '';
  }
  if (platform === 'kkbox') {
    const { kkboxUrl } = me.podcastDistribution;
    if (!kkboxUrl) {
      return '';
    }
    try {
      if (global.URL) {
        const url = new URL(kkboxUrl);
        url.searchParams.set('utm_source', 'firstory');
        url.searchParams.set('utm_medium', 'podcaster');
        url.searchParams.set('utm_campaign', 'share');
        return url.href;
      } else {
        return (
          kkboxUrl +
          '?utm_source=firstory&utm_medium=podcaster&utm_campaign=share'
        );
      }
    } catch (error) {
      return (
        kkboxUrl +
        '?utm_source=firstory&utm_medium=podcaster&utm_campaign=share'
      );
    }
  }
  if (platform === 'soundon') {
    return me.podcastDistribution.soundonUrl || '';
  }
  return '';
}

export function getPlatformUrlStartsWith(platform) {
  return platformUrlStartsWith[platform] || 'https://';
}

function getPlatformEditable(platform, me) {
  if (platform === 'rss' || platform === 'firstory') {
    return false;
  }
  if (!me) {
    return false;
  }

  const isImport = !!me.importRssUrl || !!me.switchHostRequest;
  const isManually = me.podcastDistribution && me.podcastDistribution.manually;

  if (platform === 'kkbox') {
    if (
      me.podcastDistribution &&
      me.podcastDistribution.kkboxStatus === 'NONE'
    ) {
      return true;
    }
  }
  if (platform === 'soundon') {
    return !(me.podcastDistribution && me.podcastDistribution.soundonUrl);
  }
  if (!isImport && !isManually) {
    return false;
  }
  if (!me.podcastDistribution) {
    return true;
  }
  if (platform === 'spotify') {
    return !me.podcastDistribution.spotifyId;
  }
  if (platform === 'apple') {
    return !me.podcastDistribution.applePodcastUrl;
  }
  if (platform === 'google') {
    return !me.podcastDistribution.googlePodcastUrl;
  }
  if (platform === 'pocketcast') {
    return !me.podcastDistribution.pocketcastUrl;
  }
  if (platform === 'kkbox') {
    return !me.podcastDistribution.kkboxUrl;
  }
  return false;
}

export function getPlatformColor(platform) {
  return platformColors[platform];
}

export function getDistributionData(platform, me) {
  return {
    title: getPlatformTitle(platform),
    status: getPlatformStatus(platform, me),
    url: getPlatformUrl(platform, me),
    urlStartsWith: getPlatformUrlStartsWith(platform),
    editable: getPlatformEditable(platform, me),
  };
}

export function shouldShowSwitchToFirstory(me) {
  if (!me) {
    return false;
  }
  if (me.switchHostRequest) {
    if (
      me.switchHostRequest.status === 'HAS_REDIRECT' ||
      me.switchHostRequest.status === 'SUCCESS'
    ) {
      return false;
    }
    return true;
  } else {
    if (me.importRssUrl) {
      return true;
    } else {
      return false;
    }
  }
}
