import React from 'react';
import { Link as RouterLink, useRouteMatch } from 'react-router-dom';
import cx from 'classnames';
import { makeStyles, useTheme } from '@material-ui/styles';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Slider from '@material-ui/core/Slider';
import ShareIcon from '@material-ui/icons/Share';
import MessageIcon from '@material-ui/icons/Message';
import MoneyIcon from '@material-ui/icons/MonetizationOn';
import RssFeedIcon from '@material-ui/icons/RssFeed';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import VoicemailIcon from '@material-ui/icons/Voicemail';
import ShareModal from './ShareModal';
import PlayPauseIcon from '../icons/PlayPauseIcon';
import Logo from '../Logo';
import LazyImage from '../LazyImage';
import WebPlayerContext from '../../pages/WebPlayer/context';
import useDisclosure from '../../hooks/useDisclosure';
import { getMMSSfromSeconds, imageproxy } from '../../utils';

const useStyles = makeStyles(theme => ({
  container: {
    position: 'relative',
    width: '100%',
    height: 180,
    padding: theme.spacing(2),
    display: 'flex',
    backgroundColor: theme.palette.background.default,
    boxSizing: 'border-box',
  },
  imageContainer: {
    position: 'relative',
    height: 148,
    width: 148,
    borderRadius: 10,
    overflow: 'hidden',
    marginRight: 10,
    cursor: 'pointer',
    '@media (max-width: 500px)': {
      height: 115,
      width: 115,
    },
    '&:hover': {
      opacity: 0.8,
    },
  },
  image: {
    height: '100%',
    width: '100%',
    objectFit: 'cover',
  },
  right: {
    flex: 1,
    minWidth: 0,
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
  },
  info: {
    flex: 1,
    position: 'relative',
  },
  timestamp: {
    opacity: 0.8,
    marginRight: theme.spacing(1),
    '@media (max-width: 500px)': {
      fontSize: '0.6rem',
    },
  },
  text: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  title: {
    fontWeight: 'bold',
    '@media (max-width: 500px)': {
      fontSize: '0.9rem',
    },
  },
  author: {
    display: 'inline-block',
    '@media (max-width: 500px)': {
      fontSize: '0.7rem',
    },
  },
  logoContainer: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
  },
  logo: {
    width: 18,
    height: 18,
    marginRight: 5,
    '@media (max-width: 500px)': {
      width: 12,
      height: 12,
    },
  },
  logoTitle: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    '@media (max-width: 500px)': {
      fontSize: '0.7rem',
    },
  },
  optionIconButton: {
    height: 36,
    width: 36,
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  optionIcon: {
    color: theme.palette.background.default,
  },
  controlContainer: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'flex-end',
    right: theme.spacing(2),
    bottom: theme.spacing(2),
    left: 148 + 16 + 8,
    '@media (max-width: 500px)': {
      alignItems: 'center',
      left: theme.spacing(2),
      top: 16 + 115,
      bottom: 0,
    },
  },
  sliderContainer: {
    flex: 1,
    marginBottom: -1,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(2),
    '@media (max-width: 500px)': {
      marginBottom: 5,
    },
  },
  sliderRail: {
    height: 10,
    borderRadius: 5,
    backgroundColor: theme.palette.secondary.main,
  },
  sliderTrack: {
    height: 10,
    borderRadius: 5,
    backgroundColor: theme.palette.secondary.main,
  },
  sliderThumb: {
    display: 'none',
  },
  playIcon: {
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  playPauseContainer: {
    '@media (max-width: 500px)': {
      transform: 'translateY(-10px)',
    },
  },
}));

function Option({ name, Icon, link, download, onClick }) {
  const classes = useStyles();

  const buttonProps = link
    ? {
        component: 'a',
        target: '_blank',
        rel: 'noreferrer noopener',
        href: link,
        download,
      }
    : {
        onClick,
      };

  return (
    <Grid item>
      <IconButton
        disableRipple
        color="primary"
        className={classes.optionIconButton}
        {...buttonProps}
      >
        <Icon className={classes.optionIcon} />
      </IconButton>
    </Grid>
  );
}

function OptionRow({
  user,
  record,
  websiteSetting = {},
  donationSetting,
  isShareOpen,
  onShareClick,
  onShareClose,
}) {
  const donateLink =
    user && donationSetting && donationSetting.active
      ? `https://pay.firstory.me/user/${user.urlSlug || user.id}`
      : null;
  const commentLink = record
    ? `https://open.firstory.me/story/${record.id}`
    : null;
  const voicemailLink =
    user && user.urlSlug && websiteSetting && websiteSetting.active
      ? `https://${user.urlSlug}.firstory.io/voicemail`
      : null;
  const rssUrl = user
    ? user.importRssUrl || `https://open.firstory.me/rss/user/${user.id}`
    : null;
  const shareUrl = record
    ? `https://open.firstory.me/story/${record.id}/platforms`
    : user
    ? `https://open.firstory.me/user/${user.urlSlug || user.id}/platforms`
    : 'http://firstory.me';
  return (
    <Grid container spacing={1}>
      <Option name="share" Icon={ShareIcon} onClick={onShareClick} />
      {websiteSetting.playerShowDonate && donateLink && (
        <Option name="playerShowDonate" Icon={MoneyIcon} link={donateLink} />
      )}
      {websiteSetting.playerShowComment && commentLink && (
        <Option
          name="playerShowComment"
          Icon={MessageIcon}
          link={commentLink}
        />
      )}
      {websiteSetting.playerShowVoicemail && voicemailLink && (
        <Option
          name="playerShowVoicemail"
          Icon={VoicemailIcon}
          link={voicemailLink}
        />
      )}
      {websiteSetting.playerShowDownloadAudioFile && record && (
        <Option
          name="playerShowDownloadAudioFile"
          Icon={DownloadIcon}
          link={record.url}
          download
        />
      )}
      {websiteSetting.playerShowRssFeed && rssUrl && (
        <Option name="playerShowRssFeed" Icon={RssFeedIcon} link={rssUrl} />
      )}
      <ShareModal url={shareUrl} open={isShareOpen} onClose={onShareClose} />
    </Grid>
  );
}

function EmbedController({
  title,
  subtitle,
  imageUrl,
  author,
  authorLink,
  link,
  firstRecord,
  nowPlaying,
  isLoading,
  isPlaying,
  time,
  duration,
  onPlayPauseClick,
  onForwardClick,
  onBackwardClick,
  onSliderChange,
  onSliderAfterChange,
}) {
  const isEpisode = useRouteMatch('/embed/(story|episodes)/:id');
  const { isWebPlayer } = React.useContext(WebPlayerContext);
  const nowPlayingImageUrl = nowPlaying ? nowPlaying.imageUrl : imageUrl;
  const nowPlayingAuthor = nowPlaying ? nowPlaying.author : author;
  const classes = useStyles();
  const theme = useTheme();
  const { isOpen, open, close } = useDisclosure();

  const websiteSetting =
    author && author.websiteSetting ? author.websiteSetting : {};
  const donationSetting =
    author && author.donationSetting ? author.donationSetting : {};

  const handleSliderChangeCommitted = React.useCallback(
    (e, v) => {
      onSliderAfterChange(v);
    },
    [onSliderAfterChange],
  );

  const logo = (
    <div className={classes.logoContainer}>
      <Logo
        color={theme.palette.background.default}
        backgroundColor={theme.palette.primary.main}
        className={classes.logo}
      />
      <Typography className={classes.logoTitle}>Firstory</Typography>
    </div>
  );

  return (
    <div className={classes.container}>
      <div className={classes.imageContainer} onClick={onPlayPauseClick}>
        {nowPlayingImageUrl ? (
          <LazyImage
            src={nowPlayingImageUrl}
            placeholder={imageproxy(nowPlayingImageUrl, 50)}
            alt={title}
            className={classes.image}
          />
        ) : (
          <Logo className={classes.image} />
        )}
      </div>
      <div className={classes.right}>
        <div className={classes.info}>
          <Typography
            variant="h6"
            color="primary"
            component="h1"
            className={cx(classes.text, classes.title)}
          >
            {nowPlaying ? nowPlaying.title : title}
          </Typography>
          <Link
            color="primary"
            component={RouterLink}
            to={{
              pathname:
                authorLink ||
                (nowPlayingAuthor
                  ? isWebPlayer
                    ? `/user/${nowPlayingAuthor.urlSlug || nowPlayingAuthor.id}`
                    : '/'
                  : '/'),
              state: { from: 'CONTROLLER' },
            }}
            target="_blank"
            className={cx(classes.text, classes.author)}
          >
            {nowPlayingAuthor ? nowPlayingAuthor.name : subtitle || ''}
          </Link>
          <OptionRow
            user={nowPlayingAuthor}
            record={nowPlaying || (isEpisode ? firstRecord : null)}
            websiteSetting={websiteSetting}
            donationSetting={donationSetting}
            isShareOpen={isOpen}
            onShareClick={open}
            onShareClose={close}
          />
        </div>
      </div>
      {websiteSetting.playerHideLogo ? null : link ? (
        <RouterLink to={link} target="_blank" rel="noreferrer noopener">
          {logo}
        </RouterLink>
      ) : (
        <a href="https://firstory.me" target="_blank" rel="noreferrer noopener">
          {logo}
        </a>
      )}
      <div className={classes.controlContainer}>
        <div className={classes.sliderContainer}>
          <Slider
            classes={{
              rail: classes.sliderRail,
              track: classes.sliderTrack,
              thumb: classes.sliderThumb,
            }}
            max={duration}
            value={time}
            onChange={onSliderChange}
            onChangeCommitted={handleSliderChangeCommitted}
          />
        </div>
        <Typography
          color="primary"
          variant="caption"
          className={classes.timestamp}
        >
          {getMMSSfromSeconds(time)} / {getMMSSfromSeconds(duration)}
        </Typography>
        <div className={classes.playPauseContainer}>
          <PlayPauseIcon
            button
            size={24}
            color={theme.palette.background.default}
            isLoading={isLoading}
            isPlaying={isPlaying}
            onClick={onPlayPauseClick}
            className={classes.playIcon}
          />
        </div>
      </div>
    </div>
  );
}

export default EmbedController;
