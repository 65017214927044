import React from 'react';
import { useRouteMatch } from 'react-router';
import { ThemeProvider, makeStyles } from '@material-ui/styles';
import Navbar from '../Navbar';
import Controller from '../../../components/Controller';
import WebPlayerContext from '../context';
import { darkTheme } from '../../../theme';

const useStyles = makeStyles(theme => ({
  container: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: props => (props.isEmbed ? 10 : 0),
    overflow: 'hidden',
  },
  top: {
    flex: 1,
    overflow: 'auto',
    display: 'flex',
    '@media (max-width: 900px)': {
      flexDirection: 'column',
    },
  },
  content: {
    flex: 1,
    overflow: 'auto',
    height: '100%',
    backgroundColor: props =>
      props.isEmbed
        ? theme.palette.background.paper
        : theme.palette.background.default,
  },
  '@global': {
    html: {
      height: props => (props.hideContent ? 'auto !important' : '100%'),
    },
  },
}));

function Layout({
  isEmbed,
  title,
  subtitle,
  imageUrl,
  author,
  link,
  firstRecord,
  children,
  hideController,
  hideContent,
  hideNavbar,
  shouldShowDownloadModal = true,
}) {
  const isEmbedRoute = useRouteMatch('/embed');
  const isEmbedLayout = isEmbed || isEmbedRoute;
  const {
    nowPlaying,
    isLoading,
    isPlaying,
    currentTimeRef,
    slidingValue,
    playRecord,
    playPause,
    setCurrentTime,
    setSpeed,
    slide,
    forward,
    backward,
  } = React.useContext(WebPlayerContext) || {};
  const classes = useStyles({ isEmbed: isEmbedLayout, hideContent });

  const handlePlayPauseClick = React.useCallback(() => {
    if (nowPlaying) {
      playPause();
    } else {
      if (firstRecord) {
        playRecord(firstRecord, { shouldShowDownloadModal });
      }
    }
  }, [nowPlaying, firstRecord, playRecord, playPause, shouldShowDownloadModal]);

  return (
    <div className={classes.container}>
      {!isEmbedLayout && (
        <div className={classes.top}>
          {!hideNavbar && <Navbar />}
          <div className={classes.content}>{children}</div>
        </div>
      )}
      {!hideController && (
        <Controller
          isEmbed={isEmbedLayout}
          title={title}
          subtitle={subtitle}
          imageUrl={imageUrl}
          author={author}
          link={link}
          firstRecord={firstRecord}
          nowPlaying={nowPlaying}
          isLoading={isLoading}
          isPlaying={isPlaying}
          currentTimeRef={currentTimeRef}
          slidingValue={slidingValue}
          duration={nowPlaying ? Math.round(nowPlaying.duration / 1000) : 0}
          onPlayPauseClick={handlePlayPauseClick}
          onForwardClick={forward}
          onBackwardClick={backward}
          onSpeedChange={setSpeed}
          onSliderChange={slide}
          onSliderAfterChange={setCurrentTime}
        />
      )}
      {isEmbedLayout && !hideContent && (
        <div className={classes.content}>{children}</div>
      )}
    </div>
  );
}

function LayoutWithTheme({ theme = darkTheme, ...props }) {
  return (
    <ThemeProvider theme={theme}>
      <Layout theme={theme} {...props} />
    </ThemeProvider>
  );
}

export default LayoutWithTheme;
