export const ShowImportStatus = {
  PENDING: 1,
  HAS_REDIRECT: 2,
  SUCCESS: 3,
};

export const DistributionStatus = {
  PENDING: 1,
  SUBMITTED: 2,
  ACTIVE: 3,
};
