import gql from 'graphql-tag';
import { CommentFragment } from './fragments';

export const PlayerCommentFind = gql`
  query PlayerCommentFind(
    $type: CommentTargetType!
    $id: ID!
    $first: Int
    $after: String
  ) {
    playerCommentFind(
      commentTarget: { type: $type, id: $id }
      take: $first
      after: $after
    ) {
      ...CommentFragment
    }
  }
  ${CommentFragment}
`;

export const CreateComment = gql`
  mutation CreateComment(
    $commentTarget: CommentTargetInput!
    $name: String
    $message: String
  ) {
    playerCommentCreate(
      type: 1
      commentTarget: $commentTarget
      name: $name
      message: $message
    ) {
      ...CommentFragment
    }
  }
  ${CommentFragment}
`;

export const DeleteComment = gql`
  mutation DeleteComment($commentId: ID!, $commentTarget: CommentTargetInput!) {
    audienceCommentDelete(
      commentId: $commentId
      commentTarget: $commentTarget
    ) {
      id
    }
  }
`;
