import React from 'react';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import { Page } from './Screen';
import BenchmarkLow from './assets/benchmark-1-bg.svg';
import BenchmarkMedium from './assets/benchmark-2-bg.svg';
import BenchmarkHigh from './assets/benchmark-3-bg.svg';
import BenchmarkTop from './assets/benchmark-4-bg.svg';

const useStyles = makeStyles(theme => ({
  page: {
    color: '#FBD59C',
    background: '#3F8D47',
  },
  title: {
    whiteSpace: 'pre-wrap',
    textAlign: 'center',
    marginBottom: theme.spacing(4),
  },
  value: {
    textAlign: 'center',
    fontSize: 28,
    fontWeight: 'bold',
  },
  description: {
    textAlign: 'center',
    whiteSpace: 'pre-wrap',
    marginBottom: theme.spacing(4),
  },
  image: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
}));

function Benchmark({ data }) {
  const classes = useStyles();

  const { uniqueStarts } = data.me.analyticData.aggregate;
  const benchmarks = data.me.analyticData.benchmarks.starts;

  const tier =
    !uniqueStarts || uniqueStarts < benchmarks.low
      ? 'low'
      : uniqueStarts < benchmarks.median
      ? 'medium'
      : uniqueStarts < benchmarks.high
      ? 'high'
      : 'top';
  const images = {
    low: BenchmarkLow,
    medium: BenchmarkMedium,
    high: BenchmarkHigh,
    top: BenchmarkTop,
  };
  const image = images[tier] || BenchmarkLow;

  return (
    <Page className={classes.page}>
      <Typography className={classes.title}>
        <FormattedMessage id="rewind.2021.benchmark.title" />
      </Typography>
      <Typography className={classes.value}>
        <FormattedMessage id={`rewind.2021.benchmark.${tier}`} />
      </Typography>
      <Typography className={classes.description}>
        <FormattedMessage id={`rewind.2021.benchmark.${tier}.description`} />
      </Typography>
      <img src={image} alt="benchmark" className={classes.image} />
    </Page>
  );
}

export default Benchmark;
