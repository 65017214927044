import React from 'react';
import cx from 'classnames';
import { makeStyles } from '@material-ui/styles';

const HEIGHT = 667;
const WIDTH = 375;
const RATIO = WIDTH / HEIGHT;

const useStyles = makeStyles(theme => ({
  screen: {
    height: HEIGHT,
    width: WIDTH,
    transform: props => `scale(${props.ratio})`,
    transformOrigin: 'left top',
    backgroundColor: '#2B3257',
    marginLeft: props => props.left,
    overflow: 'hidden',
  },
  page: {
    height: HEIGHT,
    width: WIDTH,
    background: props =>
      props.bgImage ? `url(${props.bgImage})` : 'transparent',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2),
  },
}));

function Screen({ children }) {
  const [ratio, setRatio] = React.useState(1);
  const [left, setLeft] = React.useState(1);

  const updateRatio = React.useCallback(() => {
    const viewWidth = window.innerWidth;
    const viewHeight = window.innerHeight;
    const viewRatio = viewWidth / viewHeight;
    const widthRatio = viewWidth / WIDTH;
    const heightRatio = viewHeight / HEIGHT;

    if (viewRatio > RATIO) {
      setRatio(heightRatio);
      setLeft((viewWidth - heightRatio * WIDTH) / 2);
    } else {
      setRatio(widthRatio);
      setLeft(0);
    }
  }, []);

  React.useEffect(() => {
    updateRatio();
    window.addEventListener('load', updateRatio);
    window.addEventListener('resize', updateRatio);
    return () => {
      window.removeEventListener('load', updateRatio);
      window.removeEventListener('resize', updateRatio);
    };
  }, [updateRatio]);

  const classes = useStyles({ ratio, left });
  return <div className={classes.screen}>{children}</div>;
}

function Page({ children, className, bgImage, ...props }) {
  const classes = useStyles({ bgImage });

  return (
    <div className="section">
      <div className={cx(classes.page, className)} {...props}>
        {children}
      </div>
    </div>
  );
}

export default Screen;
export { Page };
