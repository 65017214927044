import gql from 'graphql-tag';

export const GetMe = gql`
  query GetMe {
    me {
      id
      name
      urlSlug
      avatar
      isCreator
      account {
        id
        email
      }
    }
  }
`;

export const GetUserExists = gql`
  query GetUserExists($name: String, $email: String) {
    userExists(name: $name, email: $email)
  }
`;

export const ForgetPassword = gql`
  query ForgetPassword($email: String!) {
    forgetPassword(email: $email)
  }
`;

export const CheckResetPasswordToken = gql`
  query checkResetPasswordToken($email: String!, $token: String!) {
    checkResetPasswordToken(email: $email, token: $token)
  }
`;

export const GetMultipleAccounts = gql`
  query GetMultipleAccounts {
    me {
      id
    }
    multipleAccounts {
      id
      name
      avatar
    }
  }
`;
