import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useApolloClient } from '@apollo/react-hooks';
import useNextLocation from '../../hooks/useNextLocation';
import { GetMe } from '../../gql/v2/user';
import { useTracker } from '../../analytics/tracker';
import { loadMixpanelSdk } from '../../utils';

const AuthContext = React.createContext({});

function AuthProvider({ children, token, onTokenChange }) {
  const client = useApolloClient();
  const tracker = useTracker();
  const location = useLocation();
  const history = useHistory();
  const goNext = useNextLocation();

  const redirectUrl = React.useMemo(() => {
    const params = new URLSearchParams(location.search);
    const url = params.get('redirectUrl');
    if (!url) {
      return null;
    }
    return url;
  }, [location]);

  const authGoNext = React.useCallback(
    next => {
      goNext(next);
    },
    [goNext],
  );

  const handleLogin = React.useCallback(
    async ({ isSignup, method, token, show, next }) => {
      const user = show;
      onTokenChange(token);
      await client.clearStore();
      const mixpanel = await loadMixpanelSdk();
      mixpanel.people.set({
        $name: user.name || '',
        $email: user.email || '',
      });
      if (isSignup) {
        tracker.signupSuccess(method);
      } else {
        tracker.loginSuccess(method);
      }
      mixpanel.identify(user.id);
      authGoNext(next);
    },
    [client, onTokenChange, authGoNext, tracker],
  );

  const handleLogout = React.useCallback(async () => {
    await fetch('/logout');
    onTokenChange(null);
    await client.clearStore();
    const mixpanel = await loadMixpanelSdk();
    mixpanel.reset();
    history.push('/');
  }, [client, history, onTokenChange]);

  const value = React.useMemo(
    () => ({ token, handleLogin, handleLogout, authGoNext }),
    [token, handleLogin, handleLogout, authGoNext],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export default AuthContext;
export { AuthProvider };
