import gql from 'graphql-tag';
import { ShowInfoFragment, CategoryFragment } from './fragments';

export const GetShowInfo = gql`
  query GetShowInfo($showId: String!) {
    playerShowFindOneByUrlSlug(urlSlug: $showId) {
      ...ShowInfoFragment
    }
  }
  ${ShowInfoFragment}
`;

export const GetPodcastCategories = gql`
  query GetCategories {
    playerCategoryFind(subCategory: false) {
      ...CategoryFragment
    }
  }
  ${CategoryFragment}
`;

export const SearchShow = gql`
  query SearchShow(
    $showIds: [ID!]
    $categoryId: ID
    $queryString: String
    $first: Int
    $skip: Int
  ) {
    playerShowFind(
      showIds: $showIds
      categoryId: $categoryId
      queryString: $queryString
      take: $first
      skip: $skip
    ) {
      id
      name
      avatar
      urlSlug
    }
  }
`;

export const GetDiscoverUser = gql`
  query GetDiscoverUser {
    banner: discoverUser(type: BANNER_FEATURE) {
      id
    }
    hot: discoverUser(type: HOT, first: 10) {
      id
    }
    trending: discoverUser(type: TRENDING, first: 10) {
      id
    }
  }
`;
