import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import { Page } from './Screen';
import Title from './Title';
import { countryCodeToEmoji } from '../../../utils';

const useStyles = makeStyles(theme => ({
  from: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  country: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
  },
  emojiRow: {
    marginTop: theme.spacing(4),
  },
}));

function Region({ data, bgImage }) {
  const classes = useStyles();
  const { country, regions } = React.useMemo(() => {
    const { country } = data.me.analyticData.aggregate;
    const regions = [];
    country.sort((a, b) => b.value - a.value);
    country.forEach(c => {
      c.region.sort((a, b) => b.value - a.value);
      c.region.forEach(r => {
        regions.push({
          name: r.name,
          value: c.value * r.value,
          countryCode: c.code,
          countryName: c.name,
        });
      });
    });
    return {
      country,
      regions,
    };
  }, [data]);

  return (
    <Page bgImage={bgImage}>
      <Typography>裡面包含了</Typography>
      <Title>{regions.length}</Title>
      <Typography>個不同的城市</Typography>
      <Typography variant="body2" className={classes.from}>
        聽眾主要來自
      </Typography>
      {country.slice(0, 3).map((c, i) => (
        <Typography key={c.code} className={classes.country}>
          {i + 1}. {c.name} {c.region.length > 0 ? c.region[0].name : ''}
        </Typography>
      ))}
      <Typography variant="h4" className={classes.emojiRow}>
        {country.slice(0, 3).map(c => countryCodeToEmoji(c.code))}
      </Typography>
    </Page>
  );
}

export default Region;
