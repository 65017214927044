import React from 'react';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import { Page } from './Screen';

const useStyles = makeStyles(theme => ({
  page: {
    color: '#F8B272',
    background: '#6D447E',
  },
  title: {
    whiteSpace: 'pre-wrap',
    textAlign: 'center',
    marginBottom: theme.spacing(4),
  },
  dayContainer: {
    border: '2px solid #F8B272',
    borderRadius: 10,
    padding: theme.spacing(2),
    margin: theme.spacing(2),
  },
  day: {
    textAlign: 'center',
    fontSize: 28,
    fontWeight: 'bold',
  },
  description: {
    textAlign: 'center',
    whiteSpace: 'pre-wrap',
    marginBottom: theme.spacing(6),
  },
}));

function Duration({ data }) {
  const classes = useStyles();

  const duration = 2.5 * 24 * 60 * 60 * 1000;
  const durationHour = Math.round(duration / 1000 / 60 / 60, 1);
  const durationDay = Math.floor(durationHour / 24);

  return (
    <Page className={classes.page}>
      <Typography className={classes.title}>
        <FormattedMessage
          id="rewind.2021.duration.title"
          values={{
            hours: (
              <div className={classes.dayContainer}>
                <Typography className={classes.day}>{durationHour}</Typography>
                <FormattedMessage id="rewind.2021.duration.hour" />
              </div>
            ),
          }}
        />
      </Typography>
      <Typography className={classes.description} variant="body2">
        <FormattedMessage
          id="rewind.2021.duration.day"
          values={{
            day: durationDay,
            hour: durationHour - durationDay * 24,
          }}
        />
      </Typography>
      <Typography className={classes.description}>
        <FormattedMessage id="rewind.2021.duration.description" />
      </Typography>
    </Page>
  );
}

export default Duration;
