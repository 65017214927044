import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Page } from './Screen';
import Title from './Title';
import Image from './Image';
import image from './assets/top-episode.svg';

function TopEpisode({ data, bgImage }) {
  const topEpisode = data.me.analyticData.episodeRank[0];

  if (!topEpisode) {
    return null;
  }

  return (
    <Page bgImage={bgImage}>
      <Typography>你的</Typography>
      <Title variant="h5">{topEpisode.title}</Title>
      <Typography>是 2020 最多人聽的一集！</Typography>
      <Image src={image} />
      <Typography variant="body2">可以跟聽眾聊聊為什麼喜歡這集，</Typography>
      <Typography variant="body2">搞不好能幫助到之後的企劃喔！</Typography>
    </Page>
  );
}

export default TopEpisode;
