import gql from 'graphql-tag';

const AuthPayload = gql`
  fragment AuthPayload on AuthPayload {
    token
    isTwoFa
    show {
      id
      name
    }
  }
`;

export const AuthLoginResendOTP = gql`
  mutation AuthLoginResendOTP {
    authLoginResendOTP {
      ...AuthPayload
    }
  }
  ${AuthPayload}
`;

export const AuthLoginWithTwoFa = gql`
  mutation AuthLoginWithTwoFa($otp: String!) {
    authLoginWithTwoFa(otp: $otp) {
      ...AuthPayload
    }
  }
  ${AuthPayload}
`;

export const AuthLoginWithEmail = gql`
  mutation AuthLoginWithEmail($email: String!, $password: String!) {
    authLoginWithEmail(email: $email, password: $password) {
      ...AuthPayload
    }
  }
  ${AuthPayload}
`;

export const AuthSignupWithEmail = gql`
  mutation AuthSignupWithEmail($email: String!, $password: String!) {
    authSignupWithEmail(email: $email, password: $password) {
      ...AuthPayload
    }
  }
  ${AuthPayload}
`;
export const AuthSignupWithApple = gql`
  mutation AuthSignupWithApple($appleToken: String!) {
    authSignupWithApple(appleToken: $appleToken) {
      ...AuthPayload
    }
  }
  ${AuthPayload}
`;
export const AuthSignupWithFacebook = gql`
  mutation AuthSignupWithFacebook($fbToken: String!) {
    authSignupWithFacebook(fbToken: $fbToken) {
      ...AuthPayload
    }
  }
  ${AuthPayload}
`;
export const AuthPasswordSendResetEmail = gql`
  mutation AuthPasswordSendResetEmail($email: String!) {
    authPasswordSendResetEmail(email: $email)
  }
`;

export const AuthPasswordValidateResetToken = gql`
  mutation AuthPasswordValidateResetToken($email: String!, $token: String!) {
    authPasswordValidateResetToken(email: $email, token: $token)
  }
`;
export const AuthPasswordReset = gql`
  mutation AuthPasswordReset(
    $email: String!
    $token: String!
    $password: String!
  ) {
    authPasswordReset(email: $email, token: $token, password: $password)
  }
`;

export const AuthPasswordUpdate = gql`
  mutation AuthPasswordUpdate($oldPassword: String!, $newPassword: String!) {
    authPasswordUpdate(oldPassword: $oldPassword, newPassword: $newPassword)
  }
`;

export const AuthEmailUpdate = gql`
  mutation AuthEmailUpdate($email: String!, $password: String!) {
    authEmailUpdate(email: $email, password: $password) {
      id
      email
    }
  }
`;

export const AuthSwitchSession = gql`
  mutation AuthSwitchSession($showId: ID!) {
    authSwitchSession(showId: $showId) {
      ...AuthPayload
    }
  }
  ${AuthPayload}
`;
