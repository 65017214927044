import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';

function AlertDialog({
  variant,
  title = '',
  description = '',
  confirmLoading,
  open,
  onConfirm,
  onClose,
  onExited,
}) {
  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={open}
      onExited={onExited}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {variant === 'confirm' && (
          <>
            {!confirmLoading && (
              <Button color="primary" onClick={onClose} autoFocus>
                取消
              </Button>
            )}
            <Button color="primary" onClick={onConfirm}>
              {confirmLoading ? <CircularProgress size={24} /> : '確認'}
            </Button>
          </>
        )}
        {variant === 'info' && (
          <Button color="primary" onClick={onConfirm}>
            OK
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default AlertDialog;
