import React from 'react';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import { Page } from './Screen';

const useStyles = makeStyles(theme => ({
  page: {
    color: '#EFF88F',
    background: '#62AFB4',
  },
  title: {
    whiteSpace: 'pre-wrap',
    textAlign: 'center',
    marginBottom: theme.spacing(4),
  },
  valueContainer: {
    border: '2px solid #EFF88F',
    borderRadius: 10,
    padding: theme.spacing(2),
    marginBottom: theme.spacing(6),
  },
  label: {
    textAlign: 'center',
  },
  value: {
    textAlign: 'center',
    fontSize: 28,
    fontWeight: 'bold',
  },
  and: {
    textAlign: 'center',
    margin: theme.spacing(2),
  },
  description: {
    textAlign: 'center',
    whiteSpace: 'pre-wrap',
    marginBottom: theme.spacing(6),
  },
}));

function Donation({ data }) {
  const classes = useStyles();
  const { all } = data.myDonationIncomeOverview;
  const recurringCount = data.myDonationMembers.length;

  return (
    <Page className={classes.page}>
      <Typography className={classes.title}>
        <FormattedMessage id="rewind.2021.donation.title" />
      </Typography>
      <div className={classes.valueContainer}>
        <Typography className={classes.label}>
          <FormattedMessage
            id="rewind.2021.donation.income"
            values={{
              amount: <Typography className={classes.value}>${all}</Typography>,
            }}
          />
        </Typography>
        <Typography className={classes.and}>
          <FormattedMessage id="rewind.2021.donation.and" />
        </Typography>
        <Typography className={classes.amount}>
          <FormattedMessage
            id="rewind.2021.donation.recurring"
            values={{
              count: (
                <Typography className={classes.value}>
                  {recurringCount}
                </Typography>
              ),
            }}
          />
        </Typography>
      </div>
      <Typography className={classes.description}>
        <FormattedMessage id="rewind.2021.donation.cta" />
      </Typography>
    </Page>
  );
}

export default Donation;
