import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles({
  speedButton: {
    textTransform: 'none',
  },
});

function NormalController({ className, speed, setSpeed }) {
  const classes = useStyles();
  const anchorRef = React.useRef(null);
  const [menuOpen, setMenuOpen] = React.useState(false);

  const handleSpeedClick = React.useCallback(() => {
    setMenuOpen(true);
  }, []);

  const handleMenuClose = React.useCallback(() => {
    setMenuOpen(false);
  }, []);

  return (
    <div className={className}>
      <Button
        ref={anchorRef}
        variant={speed === 10 ? 'outlined' : 'contained'}
        size="small"
        className={classes.speedButton}
        onClick={handleSpeedClick}
      >
        {Math.floor(speed / 10)}.{speed % 10}x
      </Button>
      <Menu
        anchorEl={anchorRef.current}
        open={menuOpen}
        onClose={handleMenuClose}
      >
        {[5, 10, 15, 20].map(s => (
          <MenuItem
            key={s}
            selected={speed === s}
            onClick={() => {
              setSpeed(s);
              setMenuOpen(false);
            }}
          >
            {Math.floor(s / 10)}.{s % 10}x
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

export default NormalController;
