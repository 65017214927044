import React from 'react';
import { useLocation } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import AuthContext from '../pages/Auth/context';
import { useGetMe } from '../gql/hooks/user';
import { AuthSwitchSession } from '../gql/v2/auth';

function useSwitchAccount() {
  const location = useLocation();
  const { loading, data } = useGetMe();
  const [switchAccountMutation] = useMutation(AuthSwitchSession);
  const [menuOpen, setMenuOpen] = React.useState(false);
  const [switching, setSwitching] = React.useState(false);
  const { handleLogin } = React.useContext(AuthContext);

  const openMenu = React.useCallback(() => {
    setMenuOpen(true);
  }, []);

  const closeMenu = React.useCallback(() => {
    setMenuOpen(false);
  }, []);

  const switchAccount = React.useCallback(
    async userId => {
      if (data.me.id !== userId) {
        const result = await switchAccountMutation({
          variables: { showId: userId },
        });
        setSwitching(true);
        await handleLogin({
          ...result.data.switchAccount,
          method: 'SWITCH_ACCOUNT',
          next: location,
        });
        setSwitching(false);
        setMenuOpen(false);
      }
    },
    [location, data, switchAccountMutation, handleLogin],
  );

  return {
    switching,
    loading,
    data,
    switchAccount,
    menuOpen,
    openMenu,
    closeMenu,
  };
}

export default useSwitchAccount;
