import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Page } from './Screen';
import Title from './Title';
import Image from './Image';
import image from './assets/music.svg';

function TopDay({ data, bgImage }) {
  return (
    <Page bgImage={bgImage}>
      <Typography>Firstory 音樂庫超好用</Typography>
      <Typography>今年你使用了</Typography>
      <Title>{data.me.rewind.audioLibraryUsage}</Title>
      <Typography>次</Typography>
      <Image src={image} />
      <Typography variant="body2">多首獨家與 KKBOX 合作音樂，</Typography>
      <Typography variant="body2">歡迎下載玩玩看喔！</Typography>
    </Page>
  );
}

export default TopDay;
