import React from 'react';
import { Helmet } from 'react-helmet-async';
import ReactFullpage from '@fullpage/react-fullpage';
import { useQuery } from '@apollo/react-hooks';
import { ThemeProvider, makeStyles } from '@material-ui/styles';
import Screen from './Screen';
import Home from './Home';
import EpisodeCount from './EpisodeCount';
import TopEpisode from './TopEpisode';
import TopDay from './TopDay';
import Music from './Music';
import Feedback from './Feedback';
import Gender from './Gender';
import Country from './Country';
import Region from './Region';
import TopHour from './TopHour';
import Lifetime from './Lifetime';
import background from './assets/background.svg';
import bg2 from './assets/bg-2.svg';
import bg3 from './assets/bg-3.svg';
import bg4 from './assets/bg-4.svg';
import { GetRewind2020Data } from '../../../gql/queries';
import { darkTheme } from '../../../theme';
import { TrackerProvider } from '../../../analytics/tracker';

const useStyles = makeStyles(theme => ({
  '@global': {
    '#fp-nav ul li a span, .fp-slidesNav ul li a span': {
      backgroundColor: theme.palette.text.primary,
    },
  },
  container: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    background: `url(${background})`,
  },
}));

function Rewind() {
  const { loading, data } = useQuery(GetRewind2020Data);

  if (loading || !data || !data.me) {
    return <Home data={data} loading={loading} />;
  }

  return (
    <ReactFullpage
      navigation
      navigationPosition="right"
      scrollingSpeed={1000}
      verticalCentered={false}
      render={({ state, fullpageApi }) => {
        return (
          <ReactFullpage.Wrapper>
            <Home data={data} fullpageApi={fullpageApi} />
            <EpisodeCount data={data} bgImage={bg2} />
            <TopEpisode data={data} bgImage={bg3} />
            <TopDay data={data} bgImage={bg2} />
            <Music data={data} bgImage={bg3} />
            <Feedback data={data} bgImage={bg2} />
            <Gender data={data} bgImage={bg3} />
            <Country data={data} bgImage={bg2} />
            <Region data={data} bgImage={bg3} />
            <TopHour data={data} bgImage={bg2} />
            <Lifetime data={data} bgImage={bg4} />
          </ReactFullpage.Wrapper>
        );
      }}
    />
  );
}

function RewindPage() {
  const classes = useStyles();
  return (
    <TrackerProvider>
      <ThemeProvider theme={darkTheme}>
        <div className={classes.container}>
          <Helmet>
            <title>你的 Firstory 2020 年度回顧</title>
            <meta
              name="description"
              content="快來看你的 Firstory 2020 年度回顧"
            />
          </Helmet>
          <Screen>
            <Rewind />
          </Screen>
        </div>
      </ThemeProvider>
    </TrackerProvider>
  );
}

export default RewindPage;
