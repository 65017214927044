import gql from 'graphql-tag';

export const SearchUser = gql`
  query SearchUser(
    $queryString: String!
    $categoryId: ID
    $first: Int
    $after: String
  ) {
    searchUser(
      queryString: $queryString
      categoryId: $categoryId
      first: $first
      after: $after
    ) {
      id
      name
      authorName
      avatar
      isCreator
      urlSlug
      podcastCategory {
        textZh
        textEn
      }
    }
  }
`;

export const SearchStory = gql`
  query SearchStory(
    $queryString: String!
    $tags: [String!]
    $first: Int
    $after: String
  ) {
    searchStory(
      queryString: $queryString
      tags: $tags
      first: $first
      after: $after
    ) {
      id
      title
      imageUrl
      author {
        id
        name
        avatar
        isCreator
        urlSlug
      }
      url
      duration
      createdAt
    }
  }
`;
