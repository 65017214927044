import React from 'react';
import GoogleAnalytics from 'react-ga';
import FacebookPixel from 'react-facebook-pixel';
import TrackerContext from './context';
import { loadMixpanelSdk } from '../../utils';

function log(...args) {
  if (process.env.NODE_ENV === 'development') {
    console.log(...args);
  }
}

export function gaException(error) {
  log('gaException', error);
  GoogleAnalytics.exception({ description: error.message });
}

function useTracker() {
  const { trackerNames } = React.useContext(TrackerContext);

  const gaEvent = React.useCallback(
    (category, action, label, value) => {
      GoogleAnalytics.event(
        {
          category,
          action,
          label,
          value,
        },
        trackerNames,
      );
    },
    [trackerNames],
  );

  const mixpanelEvent = React.useCallback(
    async (eventName, properties = {}) => {
      const mixpanel = await loadMixpanelSdk();
      mixpanel.track(eventName, properties);
    },
    [],
  );

  const mixpanelPeopleIncrement = React.useCallback(async (name, amount) => {
    const mixpanel = await loadMixpanelSdk();
    mixpanel.people.increment(name, amount);
  }, []);

  const logEvent = React.useCallback(
    (category, eventName, params = {}) => {
      gaEvent(category, eventName, params.label, params.value);
      FacebookPixel.trackCustom(eventName, params);
      mixpanelEvent(eventName, params);
      log('logEvent', category, eventName, params);
    },
    [gaEvent, mixpanelEvent],
  );

  const tracker = React.useMemo(
    () => ({
      error(error) {
        gaException(error);
      },
      clickPlatformLink(params) {
        const category = 'Outbound Links';
        const eventName = `Click ${params.platform}`;
        logEvent(category, eventName, params);
      },
      clickSignupOrLogin({ from }) {
        const category = 'Auth';
        const eventName = 'ClickSignupOrLogin';
        logEvent(category, eventName, { from });
      },
      clickEmailLogin() {
        const category = 'Auth';
        const eventName = 'ClickEmailLogin';
        logEvent(category, eventName);
      },
      clickEmailSignup() {
        const category = 'Auth';
        const eventName = 'ClickEmailSignup';
        logEvent(category, eventName);
      },
      clickFBSignup() {
        const category = 'Auth';
        const eventName = 'ClickFBSignup';
        logEvent(category, eventName);
      },
      signupSuccess(method) {
        const category = 'Auth';
        const eventName = 'SignupSuccess';
        logEvent(category, eventName, { method });
      },
      loginSuccess(method) {
        const category = 'Auth';
        const eventName = 'LoginSuccess';
        logEvent(category, eventName, { method });
      },
      clickForgetPassword() {
        const category = 'Auth';
        const eventName = 'ClickForgetPassword';
        logEvent(category, eventName);
      },
      submitForgetPasswordEmail({ email }) {
        const category = 'Auth';
        const eventName = 'SubmitForgetPasswordEmail';
        logEvent(category, eventName, { email });
      },
      submitForgetPasswordToken({ email }) {
        const category = 'Auth';
        const eventName = 'SubmitForgetPasswordToken';
        logEvent(category, eventName, { email });
      },
      submitNewPassword({ email }) {
        const category = 'Auth';
        const eventName = 'SubmitNewPassword';
        logEvent(category, eventName, { email });
      },
      clickStartPodcast() {
        const category = 'Auth';
        const eventName = 'ClickStartPodcast';
        logEvent(category, eventName);
      },
      clickStartNewPodcast() {
        const category = 'Auth';
        const eventName = 'ClickStartNewPodcast';
        logEvent(category, eventName);
      },
      playStory({ duration, title, authorName, playFrom }) {
        const category = 'Feed';
        const eventName = 'PlayEpisode';
        const params = {
          duration,
          title,
          authorName,
          playFrom,
          label: title,
          value: 1,
        };
        logEvent(category, eventName, params);
        mixpanelPeopleIncrement('PlayStoryCount', 1);
      },
      finishPlayStory({ position, duration, title, authorName }) {
        const ratio = position / duration;
        const category = 'Feed';
        const eventName = 'FinishPlayStory';
        const params = {
          position,
          duration,
          ratio,
          authorName,
        };
        logEvent(category, eventName, params);
        mixpanelPeopleIncrement('PlayedStoryDuration', position);
      },
      search({ queryString }) {
        const category = 'Search';
        const eventName = 'Search';
        const params = { queryString };
        logEvent(category, eventName, params);
      },
      submitStory({ title }) {
        const category = 'Record';
        const eventName = 'SubmitStory';
        const params = { isCustomTopic: true, title };
        mixpanelPeopleIncrement('SubmitStory', 1);
        logEvent(category, eventName, params);
      },
      viewHomeTab() {
        const category = 'View';
        const eventName = 'ViewHomeTab';
        logEvent(category, eventName);
      },
      viewSearchTab() {
        const category = 'View';
        const eventName = 'ViewSearchTab';
        logEvent(category, eventName);
      },
      viewPersonalTab() {
        const category = 'View';
        const eventName = 'ViewPersonalTab';
        logEvent(category, eventName);
      },
      viewUserProfile({ username, from }) {
        const category = 'View';
        const eventName = 'ViewUserProfile';
        const params = { username, from };
        logEvent(category, eventName, params);
      },
      viewStoryDetail(params) {
        const category = 'View';
        const eventName = 'ViewStoryDetail';
        logEvent(category, eventName, params);
      },
      viewUpload() {
        const category = 'View';
        const eventName = 'ViewUpload';
        logEvent(category, eventName);
      },
      clickDownloadButton({ from }) {
        const category = 'Promotion';
        const eventName = 'ClickDownloadButton';
        const params = { from };
        logEvent(category, eventName, params);
      },
      clickTopic({ topicId, topicTitle, from }) {
        const category = 'Feed';
        const eventName = 'ClickTopic';
        const params = {
          topicTitle,
          from,
        };
        logEvent(category, eventName, params);
      },
      follow(params) {
        const category = 'Profile';
        const eventName = 'Follow';
        logEvent(category, eventName, params);
      },
      viewProfileEdit() {
        const category = 'View';
        const eventName = 'ViewProfileEdit';
        logEvent(category, eventName);
      },
      viewDistributionStatus() {
        const category = 'View';
        const eventName = 'ViewDistributionStatus';
        logEvent(category, eventName);
      },
      viewDistributionStart() {
        const category = 'View';
        const eventName = 'ViewDistributionStart';
        logEvent(category, eventName);
      },
      viewDistributionEdit() {
        const category = 'View';
        const eventName = 'ViewDistributionEdit';
        logEvent(category, eventName);
      },
      viewPlaylist(params) {
        const category = 'View';
        const eventName = 'ViewPlaylist';
        logEvent(category, eventName, params);
      },
      openFirstoryInfinityWeb() {
        const category = 'Open';
        const eventName = 'OpenFirstoryInfinityWeb';
        logEvent(category, eventName);
      },
      submitCommentStory({ authorName }) {
        const category = 'Feed';
        const eventName = 'SubmitCommentStory';
        const params = { authorName };
        logEvent(category, eventName, params);
      },
    }),
    [logEvent, mixpanelPeopleIncrement],
  );

  return tracker;
}

export default useTracker;
