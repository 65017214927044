export const colors = {
  white: '#ffffff',
  black: '#131313',
  mediumPink: '#ea6e8e',
  softBlue: '#4e86ef',
  tomato: '#fb355e',
  warmGrey: '#999999',
  rosyPink: '#fb6795',
  greyTeal: '#65a186',
  link: '#2481cc',
  // feed card gradient
  carnationPink: '#ff83a4',
  warmPink: '#f8528a',
  sunYellow: '#ffcc33',
  butterscotch: '#ffb347',
  aquaMarine: '#58e5bd',
  seaweedGreen: '#32a781',
  dodgerBlue: '#55a8f5',
  cornflower: '#5276f8',
  liliac: '#b094ff',
  cornflowerTwo: '#6d67fc',
  lightLavender: '#d6b6ff',
  cornflowerThree: '#69a0f5',
  whiteThree: '#f7f7f7',
  algaeGreen: '#25cf77',
  aquaGreen: '#13f2a4',
  rewindYellow: '#F9D270',
};

export const colorPalette = [
  [colors.carnationPink, colors.warmPink],
  [colors.sunYellow, colors.butterscotch],
  [colors.aquaMarine, colors.seaweedGreen],
  [colors.dodgerBlue, colors.cornflower],
  [colors.liliac, colors.cornflowerTwo],
  [colors.lightLavender, colors.cornflowerThree],
];
