import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

function useNextLocation() {
  const history = useHistory();
  const location = useLocation();

  const goNext = React.useCallback(
    next => {
      const background = location.state && location.state.background;
      const queryParams = new URLSearchParams(location.search);
      const nextValue = queryParams.get('next');
      const n = nextValue || next || background || '/';
      history.push('/refresh');
      console.log('goNext:', n);
      history.replace(n);
    },
    [history, location],
  );

  return goNext;
}

export default useNextLocation;
