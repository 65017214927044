import React from 'react';
import { ThemeProvider, useTheme } from '@material-ui/styles';
import AlertDialog from './AlertDialog';
import Snackbar from './Snackbar';
import { darkTheme } from '../../theme';

const errorMessages = {
  EMPTY_TITLE: '請輸入標題',
  EMPTY_DESCRIPTION: '請輸入敍述',
  EMPTY_AUDIO_FILE: '請選擇音檔',
  EMPTY_EMAIL: '請輸入信箱',
  EMPTY_PASSWORD: '請輸入密碼',
};

const AlertContext = React.createContext();

function AlertProvider({ children }) {
  const dialogPromiseRef = React.useRef();
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [dialogState, setDialogState] = React.useState(null);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarState, setSnackbarState] = React.useState('');
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [theme, setTheme] = React.useState(darkTheme);

  const openAlertDialog = React.useCallback(
    ({ variant, title, description, onConfirmClick }) => {
      setDialogOpen(true);
      setDialogState({ variant, title, description, onConfirmClick });
      return new Promise((resolve, reject) => {
        dialogPromiseRef.current = { resolve, reject };
      });
    },
    [],
  );

  const openErrorDialog = React.useCallback(
    errorMessage => {
      return openAlertDialog({
        variant: 'info',
        title: '錯誤',
        description: errorMessage,
      });
    },
    [openAlertDialog],
  );

  const handleDialogClose = React.useCallback(() => {
    if (dialogPromiseRef.current) {
      dialogPromiseRef.current.resolve(false);
    }
    setDialogOpen(false);
  }, []);

  const handleDialogConfirm = React.useCallback(async () => {
    if (dialogState && dialogState.onConfirmClick) {
      setConfirmLoading(true);
      try {
        await dialogState.onConfirmClick();
      } finally {
        setConfirmLoading(false);
      }
    }
    if (dialogPromiseRef.current) {
      dialogPromiseRef.current.resolve(true);
    }
    setDialogOpen(false);
  }, [dialogState]);

  const handleDialogExited = React.useCallback(() => {
    setDialogState(null);
  }, []);

  const showSnackbarMessage = React.useCallback((message, options = {}) => {
    const { variant, horizontal, vertical } = options;
    setSnackbarOpen(true);
    setSnackbarState({ variant, message, horizontal, vertical });
  }, []);

  const handleSnackbarClose = React.useCallback(() => {
    setSnackbarOpen(false);
  }, []);

  const contextValue = React.useMemo(() => {
    return {
      openAlertDialog,
      openErrorDialog,
      showSnackbarMessage,
      setTheme,
    };
  }, [openAlertDialog, openErrorDialog, showSnackbarMessage]);

  return (
    <AlertContext.Provider value={contextValue}>
      {children}
      <ThemeProvider theme={theme}>
        <AlertDialog
          open={dialogOpen}
          confirmLoading={confirmLoading}
          onClose={handleDialogClose}
          onExited={handleDialogExited}
          onConfirm={handleDialogConfirm}
          {...dialogState}
        />
        <Snackbar
          open={snackbarOpen}
          onClose={handleSnackbarClose}
          {...snackbarState}
        />
      </ThemeProvider>
    </AlertContext.Provider>
  );
}

function useAlert() {
  const theme = useTheme();
  const { setTheme, ...others } = React.useContext(AlertContext);
  React.useEffect(() => {
    setTheme(theme);
  }, [theme, setTheme]);
  return others;
}

export { useAlert, AlertProvider, errorMessages };
