import gql from 'graphql-tag';
import {
  UserInfoFragment,
  RecordFragment,
  CommentFragment,
} from '../fragments';

export const GetTopFeeds = gql`
  query GetTopFeeds($tags: [String!], $first: Int, $after: String) {
    feeds(filter: HOT, tags: $tags, first: $first, after: $after) {
      ...RecordFragment
    }
  }
  ${RecordFragment}
`;

export const GetUserFeeds = gql`
  query GetUserFeeds(
    $userId: ID!
    $tags: [String!]
    $playlistId: ID
    $first: Int
    $after: String
  ) {
    feeds(
      userId: $userId
      tags: $tags
      playlistId: $playlistId
      first: $first
      after: $after
    ) {
      ...RecordFragment
    }
  }
  ${RecordFragment}
`;

export const GetTopicFeeds = gql`
  query GetTopicFeeds($topicId: ID!, $first: Int, $after: String) {
    topic(topicId: $topicId) {
      id
      title
      imageUrl
      recordCount
    }
    hotFeeds: feeds(filter: HOT, topicId: $topicId) {
      ...RecordFragment
    }
    feeds(topicId: $topicId, first: $first, after: $after) {
      ...RecordFragment
    }
  }
  ${RecordFragment}
`;

export const GetStoryDetail = gql`
  query GetStoryDetail($recordId: ID!) {
    me {
      id
      name
      avatar
    }
    feed(recordId: $recordId) {
      id
      title
      url
      imageUrl
      ogImageUrl
      createdAt
      duration
      description
      descriptionHtml
      hasTranscript
      transcript
      author {
        ...UserInfoFragment
        donationRank {
          id
          name
          email
          amount
          message
        }
      }
      hideDonationRank
      topic {
        id
        title
        imageUrl
      }
      commentCount
      aggregateRating {
        ratingValue
        ratingCount
      }
      review {
        rating
      }
      tags {
        tag
      }
      podcastDistribution {
        id
        spotifyId
        spotifyStatus
        applePodcastId
        applePodcastUrl
        applePodcastStatus
        googlePodcastUrl
        googlePodcastStatus
        kkboxUrl
        kkboxStatus
      }
    }
  }
  ${UserInfoFragment}
`;

export const GetComment = gql`
  query GetComment($commentId: ID!) {
    comment(commentId: $commentId) {
      ...CommentFragment
    }
  }
  ${CommentFragment}
`;

export const GetComments = gql`
  query GetComments(
    $userId: ID
    $recordId: ID
    $commentId: ID
    $first: Int
    $after: String
  ) {
    me {
      id
      name
      avatar
    }
    comments(
      userId: $userId
      recordId: $recordId
      commentId: $commentId
      first: $first
      after: $after
    ) {
      ...CommentFragment
    }
  }
  ${CommentFragment}
`;
