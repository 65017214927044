import React from 'react';
import { Switch, Route, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import Color from 'color';
import { useQuery, useApolloClient } from '@apollo/react-hooks';
import { ThemeProvider, makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import MessageIcon from '@material-ui/icons/Message';
import MoneyIcon from '@material-ui/icons/MonetizationOn';
import VoicemailIcon from '@material-ui/icons/Voicemail';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import FavoriteIcon from '@material-ui/icons/Favorite';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Layout from '../WebPlayer/Layout';
import FlinkHeader from '../UserSite/header/FlinkHeader';
import CenterLoading from '../../components/CenterLoading';
import NotFound from '../../components/NotFound';
import PlatformIcon from '../../components/icons/PlatformIcon';
import { ViewFlink } from '../../gql/queries';
import { useGetShowInfo } from '../../gql/hooks/show';
import { useGetEpisodeDetail } from '../../gql/hooks/episode';
import { useCustomTheme } from '../../theme';
import { imageproxy } from '../../utils';
import { getPlatformTitle, getPlatformUrl } from '../../utils/distribution';
import { TrackerProvider, useTracker } from '../../analytics/tracker';
import { DistributionStatus } from '../../const/show';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    minHeight: '100%',
    backgroundColor: theme.palette.background.default,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: 450,
    margin: 'auto',
    padding: theme.spacing(4, 2),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2, 1),
    },
  },
  title: {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
  subtitle: {
    marginBottom: theme.spacing(4),
    color: theme.palette.primary.main,
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(2),
    },
  },
  image: {
    height: 200,
    width: 200,
    borderRadius: 5,
    objectFit: 'cover',
    marginBottom: theme.spacing(2),
  },
  list: {
    margin: 0,
    padding: 0,
    listStyle: 'none',
    width: '100%',
  },
  button: {
    marginBottom: theme.spacing(1),
    fontWeight: 'bold',
    width: '100%',
    height: 45,
    justifyContent: 'flex-start',
    color: () => {
      const color = Color(theme.palette.secondary.main);
      const isLight = color.isLight();
      const textColor = isLight
        ? color.darken(0.7).hex()
        : color.lighten(0.7).hex();
      return textColor;
    },
  },
  itemIcon: {
    position: 'absolute',
    left: theme.spacing(4),
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      left: theme.spacing(2),
    },
  },
  arrow: {
    position: 'absolute',
    right: theme.spacing(4),
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      right: theme.spacing(2),
    },
  },
  itemTitle: {
    flex: 1,
    textAlign: 'center',
  },
  icon: {
    width: 28,
    height: 28,
    marginRight: theme.spacing(2),
  },
  caption: {
    marginTop: theme.spacing(4),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  love: {
    fontSize: 12,
    margin: theme.spacing(0, 0.5),
  },

  firstory: {
    textDecoration: 'underline',
    fontSize: '0.8rem',
  },
}));

function LinkItem({ platform, startIcon, url, title }) {
  const classes = useStyles();
  const tracker = useTracker();
  const client = useApolloClient();
  const { userId, recordId } = useParams();
  const handleClick = React.useCallback(() => {
    tracker.clickPlatformLink({
      platform: platform || title,
      label: url,
    });
    client.query({
      query: ViewFlink,
      variables: {
        userId,
        recordId,
        platform: platform || title,
      },
    });
  }, [tracker, platform, title, url, client, recordId, userId]);

  return (
    <li>
      <Button
        key={platform}
        className={classes.button}
        color="secondary"
        variant="contained"
        size="large"
        component="a"
        href={url}
        target="_blank"
        rel="noreferrer noopener"
        onClick={handleClick}
      >
        <span className={classes.itemIcon}>{startIcon}</span>
        <span className={classes.itemTitle}>{title}</span>
        <span className={classes.arrow}>
          <NavigateNextIcon />
        </span>
      </Button>
    </li>
  );
}

function PlatformLink({ platform, data, type }) {
  const classes = useStyles();
  const url = getPlatformUrl(platform, data, type);
  const title = getPlatformTitle(platform);

  if (!url) {
    return null;
  }

  return (
    <LinkItem
      platform={platform}
      url={url}
      title={title}
      startIcon={<PlatformIcon type={platform} className={classes.icon} />}
    />
  );
}

function UserLink({ platform, user, record }) {
  const intl = useIntl();

  const titleIds = {
    donate: 'podcast.donate',
    voicemail: 'website.voicemail',
    comment: 'general.comment',
    download: 'podcast.downloadAudioFile',
  };
  const titleId = titleIds[platform];

  const urlMap = {
    donate: `https://pay.firstory.me/user/${user.urlSlug || user.id}`,
    voicemail:
      user.websiteSetting && user.websiteSetting.active && user.urlSlug
        ? `https://${user.urlSlug}.firstory.io/voicemail`
        : null,
    comment: record ? `https://open.firstory.me/story/${record.id}` : null,
    download: record ? record.url : null,
  };
  const url = urlMap[platform];

  const iconMap = {
    donate: MoneyIcon,
    voicemail: VoicemailIcon,
    comment: MessageIcon,
    download: DownloadIcon,
  };
  const Icon = iconMap[platform];

  if (!url) {
    return null;
  }

  return (
    <LinkItem
      platform={platform}
      url={url}
      title={titleId ? intl.formatMessage({ id: titleId }) : ''}
      startIcon={Icon ? <Icon /> : null}
    />
  );
}

function Distribution({ type, data }) {
  const classes = useStyles();
  const title = type === 'USER' ? data.name : data.title;
  const subtitle = type === 'USER' ? data.author : data.author.name;
  const imageUrl = type === 'USER' ? data.avatar : data.imageUrl || data.author.avatar;
  const titleLink = type === 'USER' ? `/user/${data.id}` : `/story/${data.id}`;
  const subtitleLink =
    type === 'USER' ? `/user/${data.id}` : `/user/${data.author.id}`;
  const user = type === 'USER' ? data : data.author;
  const userUrlSlug = user.urlSlug || user.id;

  const {
    userGaTrackingId,
    userFbPixelId,
    flinkShowDonate,
    flinkShowExternal,
    flinkShowRssFeed,
    flinkShowPlatforms,
    flinkShowComment,
    flinkShowVoiceMail,
    flinkShowDownloadAudioFile,
  } = user.websiteSetting || {};

  const { active } = user.donationSetting || {};

  const platforms = React.useMemo(() => {
    return [
      ...(flinkShowRssFeed ? ['rss'] : []),
      'firstory',
      'kkbox',
      'spotify',
      'apple',
      ...(user.googlePodcastBlock ? [] : ['google']),
      'pocketcast',
    ].map(platform => {
      const url = getPlatformUrl(platform, data, type);

      if (!url) {
        if (!flinkShowPlatforms) {
          return null;
        }
        if (type === 'USER') {
          return null;
        }

        return (
          <PlatformLink
            key={platform}
            platform={platform}
            data={user}
            type="USER"
          />
        );
      }

      return (
        <PlatformLink
          key={platform}
          platform={platform}
          data={data}
          type={type}
        />
      );
    });
  }, [type, data, user, flinkShowRssFeed, flinkShowPlatforms]);

  const sociaMedias = React.useMemo(() => {
    if (!user || !user.socialMedia) {
      return [];
    }

    const platforms = [
      'facebook',
      'instagram',
      'twitter',
      'youtube',
      'medium',
      'linkedIn',
    ].map(platform => {
      const title = platform.charAt(0).toUpperCase() + platform.slice(1);
      const url = user.socialMedia[platform + 'Url'];
      return { platform, title, url };
    });

    return [...platforms, ...user.socialMedia.customLinks]
      .filter(({ url }) => !!url)
      .map(({ platform, title, url }, i) => (
        <LinkItem
          key={platform || i}
          platform={platform}
          url={url}
          title={title}
          startIcon={<PlatformIcon type={platform} className={classes.icon} />}
        />
      ));
  }, [classes, user]);

  return (
    <TrackerProvider
      userUrlSlug={userUrlSlug}
      userGaTrackingId={userGaTrackingId}
      userFbPixelId={userFbPixelId}
    >
      <div className={classes.container}>
        <FlinkHeader type={type} data={data} />
        <div className={classes.content}>
          <img
            src={imageUrl ? imageproxy(imageUrl, 500) : null}
            className={classes.image}
            alt={title}
          />
          <Typography variant="h5" component="h1" className={classes.title}>
            <a
              href={titleLink}
              target="_blank"
              rel="noreferrer noopener"
              className={classes.title}
            >
              {title}
            </a>
          </Typography>
          <Typography variant="h6" component="h2" className={classes.subtitle}>
            <a
              href={subtitleLink}
              target="_blank"
              rel="noreferrer noopener"
              className={classes.subtitle}
            >
              {subtitle}
            </a>
          </Typography>
          <ul className={classes.list}>
            {flinkShowDonate && active && (
              <UserLink platform="donate" user={user} />
            )}
            {type === 'RECORD' && flinkShowComment && (
              <UserLink platform="comment" user={user} record={data} />
            )}
            {flinkShowVoiceMail && (
              <UserLink platform="voicemail" user={user} />
            )}
            {type === 'RECORD' && flinkShowDownloadAudioFile && (
              <UserLink platform="download" user={user} record={data} />
            )}
            {platforms}
            {flinkShowExternal && sociaMedias}
          </ul>
          <div className={classes.caption}>
            <Typography variant="body2" color="textSecondary">
              Made with{' '}
            </Typography>
            <FavoriteIcon color="primary" className={classes.love} />
            <Typography variant="body2" color="textSecondary">
              by{' '}
              <Link
                color="secondary"
                href="https://firstory.me"
                target="_blank"
                rel="noreferrer noopener"
                className={classes.firstory}
              >
                Firstory
              </Link>
            </Typography>
          </div>
        </div>
      </div>
    </TrackerProvider>
  );
}

function UserDistribution() {
  const classes = useStyles();
  const { userId } = useParams();
  const { loading, data } = useGetShowInfo(userId);
  // const { error } = useQuery(ViewFlink, {
  //   variables: { userId },
  // });

  const theme = useCustomTheme({
    websiteSetting: data && data.userInfo ? data.userInfo.websiteSetting : null,
  });

  if (loading) {
    return (
      <div className={classes.container}>
        <CenterLoading />
      </div>
    );
  }

  if (!data || !data.userInfo || data.userInfo.deleted) {
    return (
      <Layout hideController>
        <NotFound />
      </Layout>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Distribution type="USER" data={data.userInfo} />
    </ThemeProvider>
  );
}

function RecordDistribution() {
  const { recordId } = useParams();
  const { loading, data } = useGetEpisodeDetail(recordId);
  // const { error } = useQuery(ViewFlink, {
  //   variables: { recordId },
  // });

  const theme = useCustomTheme({
    websiteSetting: data && data.feed ? data.feed.author.websiteSetting : null,
  });

  if (loading) {
    return <CenterLoading />;
  }

  if (!data || !data.feed || data.feed.deleted) {
    return (
      <Layout hideController>
        <NotFound />
      </Layout>
    );
  }

  if (
    !data.feed.podcastDistribution ||
    data.feed.podcastDistribution.spotifyStatus !== DistributionStatus.ACTIVE
  ) {
    data.feed.podcastDistribution = data.feed.author.podcastDistribution;
  }

  return (
    <ThemeProvider theme={theme}>
      <Distribution type="RECORD" data={data.feed} />
    </ThemeProvider>
  );
}

function PodcastDistribution() {
  return (
    <Switch>
      <Route path="/user/:userId/platforms">
        <UserDistribution />
      </Route>
      <Route path="/story/:recordId/platforms">
        <RecordDistribution />
      </Route>
    </Switch>
  );
}

export default PodcastDistribution;
