import React from 'react';
import { determineUserLang } from './index';

const LangContext = React.createContext({});
const LANG_KEY = '@firstory/lang';

function ClientLangProvider({ children }) {
  const [lang, setLang] = React.useState(() => {
    const defaultLang = determineUserLang(navigator.languages || []) || 'en';
    try {
      const localLang = localStorage.getItem(LANG_KEY);
      return localLang || defaultLang;
    } catch (error) {
      return defaultLang;
    }
  });

  const onLangChange = React.useCallback(lang => {
    setLang(lang);
    try {
      localStorage.setItem(LANG_KEY, lang);
    } catch (error) {}
  }, []);

  const value = React.useMemo(() => {
    return {
      lang,
      onLangChange,
    };
  }, [lang, onLangChange]);

  return <LangContext.Provider value={value}>{children}</LangContext.Provider>;
}

function ServerLangProvider({ lang, children }) {
  const value = React.useMemo(() => {
    return { lang };
  }, [lang]);

  return <LangContext.Provider value={value}>{children}</LangContext.Provider>;
}

export { LangContext, ClientLangProvider, ServerLangProvider };
