import React from 'react';
import { ApolloClient } from 'apollo-client';
import { createUploadLink } from 'apollo-upload-client';
import { setContext } from 'apollo-link-context';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { customFetch } from '../utils';
import config from '../config';

const TOKEN_KEY = '@firstory/token';
let localToken = null;
try {
  localToken = localStorage.getItem(TOKEN_KEY);
} catch (error) {
  console.log(error);
}

// function getAuthEndpoint() {
//   const { protocol, host } = window.location;
//   return `${protocol}//${host}/api`;
// }

function useApollo() {
  const tokenRef = React.useRef(localToken);
  const [token, setToken] = React.useState(localToken)

  const client = React.useMemo(() => {
    const authLink = setContext(({ operationName }, previousContext) => {
      const token = tokenRef.current;
      let uri = config.endpoints.simple;
      if (/^(GetDiscoverUser)/.test(operationName)) {
        uri = config.endpoints.v1;
      }

      return {
        uri,
        headers: {
          ...previousContext.headers,
          authorization: token ? `Bearer ${token}` : '',
        },
      };
    });

    const httpLink = createUploadLink({
      uri: config.endpoints.simple,
      fetch: customFetch,
    });

    return new ApolloClient({
      link: authLink.concat(httpLink),
      cache: new InMemoryCache().restore(window.__APOLLO_STATE__),
    });
  }, []);

  const onTokenChange = React.useCallback(token => {
    tokenRef.current = token;
    setToken(token)
    try {
      localStorage.setItem(TOKEN_KEY, token);
    } catch (error) {
      console.log(error);
    }
  }, []);

  return { client, token, tokenRef, onTokenChange };
}

export default useApollo;
