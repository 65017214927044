import React from 'react';
import { Helmet } from 'react-helmet-async';

function Redirect({ to }) {
  React.useEffect(() => {
    window.location.replace(to);
  }, [to]);

  return null;
}

function getPlatform() {
  const userAgent = window.navigator.userAgent;
  if (/android/i.test(userAgent)) {
    return 'android';
  }
  if (/iPad|iPhone|iPod/i.test(userAgent)) {
    return 'ios';
  }
  return 'web';
}

function OneLink() {
  const [platform, setPlatform] = React.useState(null);

  React.useEffect(() => {
    const platform = getPlatform();
    setPlatform(platform);
  }, []);

  let to = 'https://firstory.me/';
  if (platform === 'ios') {
    to = 'https://bit.ly/2lzRJYz';
  }
  if (platform === 'android') {
    to = 'https://bit.ly/2mbPxqo';
  }
  if (platform) {
    return <Redirect to={to} />;
  }

  const title = 'Firstory - 聽故事與 Podcast';
  const description =
    '最棒的中文 Podcast 的平台，讓你聽到世界的角落。大家都在建立聲音品牌了，快點一起加入了吧！';

  return (
    <Helmet>
      <title>Firstory</title>
      <meta name="description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:type" content="website" />
      <meta property="og:description" content={description} />
      <meta property="og:url" content="https://open.firstory.me/store" />
      <meta property="og:image" content="/preview-201910221240.png" />
      <meta property="al:android:url" content="firstory://topics" />
      <meta property="al:android:package" content="com.firstory" />
      <meta property="al:android:app_name" content={title} />
      <meta property="al:ios:url" content="firstory://topics" />
      <meta property="al:ios:app_store_id" content="1351170844" />
      <meta property="al:ios:app_name" content={title} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={`https://open.firstory.me/store`} />
    </Helmet>
  );
}

export default OneLink;
