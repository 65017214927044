import en from './en';
import zh from './zh';

export const defaultLang = 'en';

export const supportedLangs = {
  en: 'English',
  zh: '中文',
};

export function determineUserLang(acceptedLangs) {
  const acceptedLangCodes = acceptedLangs.map(stripCountry);

  const supportedLangCodes = Object.keys(supportedLangs);

  const matchingLangCode = acceptedLangCodes.find(code =>
    supportedLangCodes.includes(code),
  );

  return matchingLangCode || defaultLang;
}

function stripCountry(lang) {
  return lang.trim().replace('_', '-').split('-')[0];
}

export const messages = {
  en,
  zh: {
    ...en,
    ...zh,
  },
};
