import React from 'react';
import { useQuery } from '@apollo/react-hooks';

function usePagination({ dataKey, query, variables, ...options }) {
  const firstVariables = React.useMemo(() => {
    return { ...variables, first: 20 };
  }, [variables]);

  const { error, networkStatus, data, fetchMore } = useQuery(query, {
    variables: firstVariables,
    ...options,
  });

  const [hasMore, setHasMore] = React.useState(true);
  const [isFetchingAfter, setIsFetchingAfter] = React.useState(false);
  const loading = networkStatus === 1 || networkStatus === 2;
  const arrayData = data ? data[dataKey] : null;

  const loadMore = React.useCallback(
    async page => {
      if (isFetchingAfter || !arrayData || arrayData.length === 0 || !hasMore) {
        return;
      }
      setIsFetchingAfter(true);
      const last = arrayData[arrayData.length - 1];
      const v = {
        ...variables,
        first: 20,
      };
      if (options.useSkip) {
        v.skip = arrayData ? arrayData.length : null;
      } else {
        v.after = last ? last.id : null;
      }
      const result = await fetchMore({
        variables: v,
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) {
            return prev;
          }
          if (fetchMoreResult[dataKey].length === 0) {
            setHasMore(false);
            return prev;
          }
          return {
            ...prev,
            [dataKey]: [...prev[dataKey], ...fetchMoreResult[dataKey]],
          };
        },
      });
      setIsFetchingAfter(false);
      return result;
    },
    [isFetchingAfter, arrayData, hasMore, variables, options.useSkip, fetchMore, dataKey],
  );

  return {
    error,
    data,
    loading,
    hasMore,
    loadMore,
    isFetchingAfter,
  };
}

export default usePagination;
