import React from 'react';
import PublicIcon from '@material-ui/icons/Public';
import Logo from '../Logo';

const srcMap = {
  apple: '/assets/platforms/apple-podcasts-small.png',
  google: '/assets/platforms/google-podcasts.svg',
  pocketcast: '/assets/platforms/pocketcast-small.png',
  spotify: '/assets/platforms/spotify.svg',
  kkbox: '/assets/platforms/kkbox.png',
  soundon: '/assets/platforms/soundon-small.png',
  rss: '/assets/platforms/rss.svg',
  facebook: '/assets/platforms/facebook.svg',
  instagram: '/assets/platforms/instagram.svg',
  twitter: '/assets/platforms/twitter.svg',
  youtube: '/assets/platforms/youtube.svg',
  linkedIn: '/assets/platforms/linkedin.svg',
  medium: '/assets/platforms/medium.svg',
};

function PlatformIcon({ type, className, style }) {
  if (!type) {
    return <PublicIcon className={className} style={style} />;
  }

  if (type === 'firstory') {
    return <Logo className={className} style={style} />;
  }

  const src = srcMap[type];
  if (!src) {
    return null;
  }
  return <img src={src} alt={type} className={className} style={style} />;
}

export default PlatformIcon;
