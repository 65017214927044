import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import cx from 'classnames';
import { makeStyles } from '@material-ui/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Link from '@material-ui/core/Link';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ForwardIcon from '@material-ui/icons/Forward10';
import ReplayIcon from '@material-ui/icons/Replay10';
import Slider from '@material-ui/core/Slider';
import SpeedController from './SpeedController';
import PlayPauseIcon from '../icons/PlayPauseIcon';
import Logo from '../../components/Logo';
import { getMMSSfromSeconds } from '../../utils';

const useStyles = makeStyles({
  container: {
    position: 'relative',
    height: 90,
    width: '100%',
    paddingLeft: 30,
    paddingRight: 30,
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    backgroundColor: '#1b1b1b',
    boxSizing: 'border-box',
    '@media(max-width:500px)': {
      paddingLeft: 10,
      paddingRight: 10,
    },
  },
  image: {
    height: 50,
    width: 50,
    borderRadius: 5,
    marginRight: 20,
    objectFit: 'cover',
    '@media(max-width:500px)': {
      marginRight: 10,
    },
  },
  smallIcon: {
    width: 30,
    height: 30,
  },
  playIcon: {
    marginLeft: 10,
    marginRight: 10,
  },
  timestamp: {
    marginLeft: 10,
    marginRight: 10,
    opacity: 0.8,
  },
  info: {
    flex: 1,
    height: 70,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    paddingLeft: 10,
    paddingRight: 10,
  },
  title: {
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  speedControl: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '&:hover': {
      '& $speedAddIcon': {
        opacity: 1,
      },
    },
  },
  speedAddIcon: {
    opacity: 0,
    transition: 'all 0.5s',
  },
  speedButton: {
    marginTop: 5,
    marginBottom: 5,
    textTransform: 'none',
  },
  hideOnSm: {
    '@media(max-width:700px)': {
      display: 'none',
    },
  },
  slider: {
    position: 'absolute',
    top: -13,
    left: 0,
    right: 0,
    '@media (pointer: coarse)': {
      top: -20,
    },
  },
});

function NormalController({
  nowPlaying,
  isLoading,
  isPlaying,
  time,
  duration,
  onPlayPauseClick,
  onForwardClick,
  onBackwardClick,
  onSpeedChange,
  onSliderChange,
  onSliderAfterChange,
}) {
  const classes = useStyles();
  const isSmall = useMediaQuery('(max-width: 700px)');
  const [speed, setSpeed] = React.useState(10);
  const { title, author, imageUrl } = nowPlaying ? nowPlaying : {};

  const handleSliderChangeCommitted = React.useCallback(
    (e, v) => {
      onSliderAfterChange(v);
    },
    [onSliderAfterChange],
  );

  React.useEffect(() => {
    onSpeedChange(speed / 10);
  }, [speed, onSpeedChange]);

  return (
    <div className={classes.container}>
      {imageUrl ? (
        <img
          src={imageUrl}
          className={cx(classes.image, classes.hideOnSm)}
          alt={title}
        />
      ) : (
        <Logo className={cx(classes.image, classes.hideOnSm)} />
      )}
      <IconButton onClick={onBackwardClick} className={classes.hideOnSm}>
        <ReplayIcon className={classes.smallIcon} />
      </IconButton>
      <PlayPauseIcon
        color="white"
        size={isSmall ? 30 : 40}
        button={isSmall ? false : true}
        isLoading={isLoading}
        isPlaying={isPlaying}
        onClick={onPlayPauseClick}
      />
      <IconButton onClick={onForwardClick} className={classes.hideOnSm}>
        <ForwardIcon className={classes.smallIcon} />
      </IconButton>
      <Typography variant="caption" className={classes.timestamp}>
        {getMMSSfromSeconds(time)} / {getMMSSfromSeconds(duration)}
      </Typography>
      <div className={classes.info}>
        <Typography className={classes.title}>{title}</Typography>
        {author ? (
          <Link
            component={RouterLink}
            to={{
              pathname: `/user/${author.urlSlug || author.id}`,
              state: { from: 'CONTROLLER' },
            }}
          >
            {author.name}
          </Link>
        ) : null}
      </div>
      <SpeedController speed={speed} setSpeed={setSpeed} />
      <div className={classes.slider}>
        <Slider
          max={duration}
          value={time}
          onChange={onSliderChange}
          onChangeCommitted={handleSliderChangeCommitted}
        />
      </div>
    </div>
  );
}

export default NormalController;
