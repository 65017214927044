import gql from 'graphql-tag';

export const GetMe = gql`
  query GetMe {
    studioShowFindOne {
      id
      name
      avatar
      categories {
        id
        nameZh
        nameEn
      }
      import {
        originRssUrl
        status
      }
    }
    studioUserFindOne {
      id
      email
      nationId
    }
    studioShowFind {
      id
      name
      avatar
    }
  }
`;
