import gql from 'graphql-tag';
import { UserInfoFragment, RecordFragment } from '../fragments';

export const GetUserInfo = gql`
  query GetUserInfo($userId: ID!) {
    me {
      id
      roles {
        name
      }
    }
    userInfo(id: $userId) {
      ...UserInfoFragment
      ogImageUrl
    }
  }
  ${UserInfoFragment}
`;

export const GetPodcastCategories = gql`
  query GetPodcastCategories {
    podcastCategories {
      id
      value
      textEn
      textZh
      subCategories {
        id
        value
        textEn
        textZh
      }
    }
  }
`;

export const GetUserPlaylists = gql`
  query GetUserPlaylists($userId: ID!) {
    userInfo(id: $userId) {
      ...UserInfoFragment
      ogImageUrl
    }
    playlists(userId: $userId) {
      id
      title
      description
      recordCount
      previewRecords {
        id
        title
        imageUrl
      }
    }
  }
  ${UserInfoFragment}
`;

export const GetPlaylistDetail = gql`
  query GetPlaylistDetail($playlistId: ID!, $first: Int, $after: String) {
    playlist(playlistId: $playlistId) {
      id
      title
      description
      recordCount
      author {
        ...UserInfoFragment
        ogImageUrl
      }
    }
    feeds(playlistId: $playlistId, first: $first, after: $after) {
      ...RecordFragment
    }
  }
  ${UserInfoFragment}
  ${RecordFragment}
`;

export const GetUserVoiceMailBoxes = gql`
  query GetUserVoiceMailBoxes(
    $userId: ID!
    $active: Boolean
    $first: Int
    $after: String
  ) {
    userInfo(id: $userId) {
      ...UserInfoFragment
      ogImageUrl
    }
    voiceMailBoxes(
      userId: $userId
      active: $active
      first: $first
      after: $after
    ) {
      id
      createdAt
      title
      description
      active
      owner {
        id
        name
        avatar
      }
    }
  }
  ${UserInfoFragment}
`;

export const GetVoiceMailBoxDetail = gql`
  query GetVoiceMailBoxDetail($id: ID!) {
    voiceMailBox(id: $id) {
      id
      createdAt
      title
      description
      active
      owner {
        ...UserInfoFragment
        ogImageUrl
      }
    }
  }
  ${UserInfoFragment}
`;

export const GetRewind2020Data = gql`
  query GetRewind2020Data {
    me {
      id
      name
      avatar
      createdAt
      rewind {
        audioLibraryUsage
        recordCount
        duration
        feedbackCount
      }
      analyticData {
        aggregate {
          age {
            age
            counts {
              MALE
              FEMALE
              NON_BINARY
              NOT_SPECIFIED
            }
          }
          country {
            name
            code
            value
            region {
              name
              value
            }
          }
          hourlyUniqueStarts
        }
        episodeRank {
          id
          title
          imageUrl
          analyticData {
            aggregate {
              starts
            }
          }
        }
        dataByDate(from: "2020-01-01", to: "2020-12-31") {
          starts {
            date
            value
          }
        }
      }
    }
  }
`;

export const ViewFlink = gql`
  query ViewFlink($userId: ID, $recordId: ID, $platform: String) {
    viewFlink(userId: $userId, recordId: $recordId, platform: $platform) {
      id
    }
  }
`;

export const GetMyDonations = gql`
  query GetMyDonations($first: Int, $after: String) {
    myDonations(first: $first, after: $after) {
      id
      createdAt
      to {
        id
        name
        avatar
      }
      amount
      name
      message
    }
  }
`;

export const GetMySponsorships = gql`
  query GetMySponsorships($first: Int, $after: String) {
    mySponsorships(first: $first, after: $after) {
      id
      tier {
        id
        type
        title
        descriptionHtml
        successDescriptionHtml
        ownerDonationSetting {
          owner {
            id
            name
            avatar
          }
        }
      }
      subscriptionPayment {
        status
        createdAt
        nextPaymentDate
        canceledDate
      }
    }
  }
`;

export const GetSponsorshipDetail = gql`
  query GetSponsorshipDetail($id: ID!) {
    sponsorshipDetail(id: $id) {
      id
      tier {
        id
        type
        title
        descriptionHtml
        successDescriptionHtml
        ownerDonationSetting {
          owner {
            id
            name
            avatar
          }
        }
      }
      subscriptionPayment {
        status
        createdAt
        nextPaymentDate
        canceledDate
      }
    }
  }
`;

export const GetRewind2021Data = gql`
  query GetRewind2021Data {
    me {
      id
      name
      avatar
      createdAt
      account {
        id
        location
      }
      rewind {
        duration
        feedbackCount
        musicInsertionCount
        firstRecordCreatedAt
      }
      analyticData {
        benchmarks {
          starts {
            low
            median
            high
          }
        }
        aggregate {
          uniqueStarts
          age {
            age
            counts {
              MALE
              FEMALE
              NON_BINARY
              NOT_SPECIFIED
            }
          }
          country {
            name
            code
            value
            region {
              name
              value
            }
          }
          hourlyUniqueStarts
        }
        episodeRank {
          id
          title
          imageUrl
          analyticData {
            aggregate {
              starts
            }
          }
        }
      }
    }
    myDonationIncomeOverview {
      all
    }
    myDonationMembers(type: RECURRING) {
      email
    }
  }
`;
