import React from 'react';
import { FormattedMessage } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import { Page } from './Screen';
import MusicSvg from './assets/music.svg';

const useStyles = makeStyles(theme => ({
  page: {
    color: '#A1F4C7',
    background: '#5945D4',
    textAlign: 'center',
  },
  title: {
    marginBottom: theme.spacing(6),
  },
  image: {
    width: 150,
    height: 150,
    borderRadius: 10,
    marginBottom: theme.spacing(2),
  },
}));

function Music({ data }) {
  const classes = useStyles();
  const musicInsertionCount = data.me.rewind.musicInsertionCount;

  return (
    <Page className={classes.page}>
      <Typography className={classes.title}>
        <FormattedMessage
          id="rewind.2021.music.title"
          values={{ count: musicInsertionCount }}
        />
      </Typography>
      <img src={MusicSvg} alt="music" className={classes.image} />
      <Typography>
        <FormattedMessage id="rewind.2021.music.description" />
      </Typography>
    </Page>
  );
}

export default Music;
