import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Color from 'color';
import { makeStyles } from '@material-ui/styles';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { useAlert } from '../Alert';
import PlatformIcon from '../icons/PlatformIcon';

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: Color(theme.palette.background.paper).fade(0.3).toString(),
  },
  header: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(1),
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  item: {
    margin: theme.spacing(1),
  },
  icon: {
    width: 36,
    height: 36,
  },
}));

function ShareModal({ url, open, onClose }) {
  const classes = useStyles();
  const intl = useIntl();
  const { showSnackbarMessage } = useAlert();

  const handleCopy = React.useCallback(() => {
    showSnackbarMessage(intl.formatMessage({ id: 'general.copied' }), {
      variant: 'success',
      vertical: 'top',
      horizontal: 'center',
    });
  }, [intl, showSnackbarMessage]);

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      PaperProps={{
        className: classes.container,
      }}
    >
      <div className={classes.header}>
        <IconButton
          edge="start"
          color="inherit"
          onClick={onClose}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
      </div>
      <div className={classes.content}>
        <div className={classes.item}>
          <a
            target="_blank"
            rel="noreferrer noopener"
            href={`https://www.facebook.com/sharer/sharer.php?u=${url}`}
          >
            <PlatformIcon type="facebook" className={classes.icon} />
          </a>
        </div>
        <div className={classes.item}>
          <a
            target="_blank"
            rel="noreferrer noopener"
            href={`http://twitter.com/share?url=${url}`}
          >
            <PlatformIcon type="twitter" className={classes.icon} />
          </a>
        </div>
        <div className={classes.item}>
          <CopyToClipboard text={url} onCopy={handleCopy}>
            <Button>
              <FormattedMessage id="general.copy" />
            </Button>
          </CopyToClipboard>
        </div>
      </div>
    </Dialog>
  );
}

export default ShareModal;
