import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import NormalController from './NormalController';
import EmbedController from './EmbedController';
import useAnimationFrame from '../../hooks/useAnimationFrame';

function Controller({
  isEmbed,
  title,
  subtitle,
  imageUrl,
  author,
  authorLink,
  link,
  firstRecord,
  nowPlaying,
  isLoading,
  isPlaying,
  duration,
  currentTimeRef,
  slidingValue,
  onPlayPauseClick,
  onForwardClick,
  onBackwardClick,
  onSpeedChange,
  onSliderChange,
  onSliderAfterChange,
}) {
  const isEmbedRoute = useRouteMatch('/embed');
  const [currentTime, setCurrentTime] = React.useState(0);
  const time = slidingValue >= 0 ? slidingValue : currentTime;

  useAnimationFrame(() => {
    setCurrentTime(currentTimeRef.current);
  }, isPlaying);

  function handleSliderChange(event, value) {
    onSliderChange(value);
  }

  function handleSliderAfterChange() {
    onSliderAfterChange(slidingValue);
  }

  const props = {
    title,
    subtitle,
    imageUrl,
    author,
    authorLink,
    link,
    firstRecord,
    nowPlaying,
    isLoading,
    isPlaying,
    duration,
    time,
    onPlayPauseClick,
    onBackwardClick,
    onForwardClick,
    onSpeedChange,
    onSliderChange: handleSliderChange,
    onSliderAfterChange: handleSliderAfterChange,
  };

  if (isEmbed || isEmbedRoute) {
    return <EmbedController {...props} />;
  }
  return <NormalController {...props} />;
}

export default Controller;
