import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Page } from './Screen';
import Title from './Title';
import Image from './Image';
import image from './assets/episode-count.svg';

function EpisodeCount({ data, bgImage }) {
  const description = React.useMemo(() => {
    const { recordCount } = data.me.rewind;
    if (recordCount < 12) {
      return '12 月已經這麼冷，我們怎麼離得越來越遠呢 QAQ';
    }
    if (recordCount < 24) {
      return '後台功能都還熟悉嗎？相信你會越用越上手！';
    }
    if (recordCount < 48) {
      return '做穩自己的關鍵，雙週更週更都不能框架你的創意';
    }
    if (recordCount < 72) {
      return '你是超級認真的 Podcaster，穩紮穩打以週更打天下';
    }
    if (recordCount < 96) {
      return '你有點瘋狂耶，到底怎麼辦到的拜託跟我們分享';
    }
    return '讓我們封你一個  Podcast 之鬼，2020 爆發真的是靠你 Carry';
  }, [data]);

  return (
    <Page bgImage={bgImage}>
      <Typography>你今年總共在 Firstory 上傳</Typography>
      <Title>{data.me.rewind.recordCount}</Title>
      <Typography>集</Typography>
      <Image src={image} />
      <Typography variant="body2">{description}</Typography>
    </Page>
  );
}

export default EpisodeCount;
