import { ShowImportStatus } from '../const/show';

export function transformArray(arr, fn) {
  if (!arr) {
    return arr;
  }
  return arr.map(fn);
}

export function transformShow(show) {
  if (!show) {
    return show;
  }
  return {
    ...show,
    ...show.step,
    importRssUrl: show.import
      ? show.import.status === ShowImportStatus.SUCCESS
        ? null
        : show.import.originRssUrl
      : null,
    switchHostRequest: show.import,
    podcastDistribution: transformDistribution(show.distributions),
    socialMedia: transformExternalLinks(show.externalLinks),
    externalLinks: show.externalLinks,
  };
}

export function transformShowArray(arr) {
  if (!arr) {
    return arr;
  }
  return arr.map(transformShow).filter(s => !!s && s.name);
}

export function transformCategory(c) {
  if (!c) {
    return c;
  }

  return {
    ...c,
    textZh: c.nameZh,
    textEn: c.nameEn,
    parentCategory: transformCategory(c.parentCategory),
  };
}

export function transformEpisode(episode) {
  if (!episode) {
    return episode;
  }

  const now = new Date();

  return {
    ...episode,
    aggregateRating: {
      ratingValue: 0,
      ratingCount: 0,
    },
    author: transformShow(episode.show),
    isDraft: !episode.publishedAt || episode.publishedAt < now.toISOString(),
    url: episode.audioUrl,
    createdAt: episode.publishedAt || now,
    scheduledAt: episode.setting ? episode.setting.schedule : null,
    podcastDistribution: transformDistribution(episode.distributions),
  };
}

export function transformComment(comment) {
  if (!comment) {
    return comment;
  }
  return {
    ...comment,
    createdAt: new Date().toISOString(),
    author: {
      name: comment.name,
    },
    type: comment.textComment ? 'TEXT' : comment.audioComment ? 'RECORD' : null,
    message: comment.textComment ? comment.textComment.message : '',
    ...comment.audioComment,
    comments: transformArray(comment.comments, transformComment),
  };
}

export function transformDistribution(distributions) {
  const distribution = {};

  if (!distributions) {
    return distribution;
  }

  const platformKeyPrefix = [
    null,
    'applePodcast',
    'breaker',
    'castbox',
    'castro',
    'google',
    'kkbox',
    'overcast',
    'playerFM',
    'pocketcast',
    'podcastAddict',
    'radiopublic',
    'soundon',
    'spotify',
    'stitcher',
  ];
  const status = ['NONE', 'PENDING', 'SUBMITTED', 'ACTIVE'];
  distributions.forEach(d => {
    const p = platformKeyPrefix[d.platformType];
    const s = status[d.status] || 'NONE';

    if (p) {
      distribution[p + 'Status'] = s;
      distribution[p + 'Id'] = d.platformId;
      distribution[p + 'Url'] = d.platformUrl;
    }
  });

  return distribution;
}

export function transformExternalLinks(externalLinks) {
  const results = {
    customLinks: [],
  };

  const platformKeyPrefix = [
    null,
    null,
    'facebook',
    'instagram',
    'twitter',
    'linkedIn',
    'medium',
    'youtube',
  ];

  if (!externalLinks) {
    return results;
  }

  externalLinks.forEach(e => {
    if (e.type === 1) {
      results.customLinks.push({
        id: e.title,
        title: e.title,
        url: e.url,
      });
    } else {
      const p = platformKeyPrefix[e.type];
      if (p) {
        results[p + 'Url'] = e.url;
      }
    }
  });
  return results;
}
