import React from 'react';
import { gaException } from '../analytics/tracker';

class ErrorBoundary extends React.Component {
  // static getDerivedStateFromError() {
  //   return {};
  // }

  // componentDidCatch(error, info) {
  //   gaException(error);
  // }

  render() {
    return this.props.children;
  }
}

export default ErrorBoundary;
