import React from 'react';
import { FormattedMessage } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/styles';
import { Page } from './Screen';

const useStyles = makeStyles(theme => ({
  page: {
    color: '#A1F4C7',
    background: '#5945D4',
    textAlign: 'center',
  },
  title: {
    marginBottom: theme.spacing(6),
    whiteSpace: 'pre-line',
  },
  image: {
    width: 150,
    height: 150,
    borderRadius: 10,
    marginBottom: theme.spacing(2),
  },
}));

function TopEpisode({ data }) {
  const classes = useStyles();
  const topEpisode = data.me.analyticData.episodeRank[0];

  if (!topEpisode) {
    return null;
  }

  return (
    <Page className={classes.page}>
      <Typography className={classes.title}>
        <FormattedMessage id="rewind.2021.episodeRank.title" />
      </Typography>
      <Avatar
        src={topEpisode.imageUrl || data.me.avatar}
        alt={topEpisode.title}
        className={classes.image}
      />
      <Typography className={classes.title}>{topEpisode.title}</Typography>
      <Typography>
        <FormattedMessage id="rewind.2021.episodeRank.description" />
      </Typography>
    </Page>
  );
}

export default TopEpisode;
