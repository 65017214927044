import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import PlayFilledIcon from '@material-ui/icons/PlayCircleFilled';
import PlayIcon from '@material-ui/icons/PlayArrow';
import PauseFilledIcon from '@material-ui/icons/PauseCircleFilled';
import PauseIcon from '@material-ui/icons/Pause';
import LoadingIcon from '@material-ui/icons/MoreHoriz';

function PlayPauseIcon({
  className,
  button,
  size = 50,
  fill,
  buttonSize,
  color = 'black',
  isLoading,
  isPlaying,
  onClick,
}) {
  const Icon = isLoading
    ? LoadingIcon
    : isPlaying
    ? fill
      ? PauseFilledIcon
      : PauseIcon
    : fill
    ? PlayFilledIcon
    : PlayIcon;

  if (button) {
    return (
      <IconButton
        aria-label="play-pause"
        size={buttonSize}
        onClick={onClick}
        className={className}
      >
        <Icon style={{ color, fontSize: size }} />
      </IconButton>
    );
  }

  return (
    <Icon
      style={{ color, fontSize: size }}
      className={className}
      onClick={onClick}
    />
  );
}

export default PlayPauseIcon;
