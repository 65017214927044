import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import ArrowDown from '@material-ui/icons/KeyboardArrowDown';
import AuthContext from '../../Auth/context';
import { Page } from './Screen';
import Title from './Title';
import Image from './Image';
import AlbumCover from '../../../components/AlbumCover';
import MultipleAccountMenu from '../../../components/MultipleAccountMenu';
import image from './assets/home.svg';
import bgImage from './assets/bg-1.svg';

const useStyles = makeStyles(theme => ({
  start: {
    width: 200,
    marginBottom: theme.spacing(2),
  },
  meWrapper: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: theme.spacing(2),
  },
  name: {
    fontWeight: 'bold',
    marginLeft: theme.spacing(2),
  },
  meContainer: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: theme.spacing(1),
  },
  login: {
    width: 200,
  },
}));

function Home({ data, loading, fullpageApi }) {
  const classes = useStyles();
  const location = useLocation();
  const auth = React.useContext(AuthContext);

  const start = React.useCallback(() => {
    fullpageApi.moveSectionDown();
  }, [fullpageApi]);

  return (
    <Page bgImage={bgImage}>
      <Typography>你的</Typography>
      <Title>2020</Title>
      <Typography>Firstory 年度回顧</Typography>
      <Image src={image} />
      {data && data.me ? (
        <>
          <div className={classes.meWrapper}>
            <div className={classes.meContainer}>
              <AlbumCover
                imageUrl={data.me.avatar}
                createdAt={data.me.createdAt}
                className={classes.avatar}
                alt={data.me.name}
              />
              <Typography className={classes.name}>{data.me.name}</Typography>
            </div>
            <MultipleAccountMenu>
              {openMenu => (
                <IconButton size="small" onClick={openMenu}>
                  <ArrowDown />
                </IconButton>
              )}
            </MultipleAccountMenu>
          </div>
          <Button
            size="large"
            variant="contained"
            className={classes.start}
            onClick={start}
          >
            立即開始
          </Button>
          <Button
            size="small"
            className={classes.logout}
            onClick={auth.handleLogout}
          >
            登出
          </Button>
        </>
      ) : loading ? (
        <CircularProgress color="white" />
      ) : (
        <Button
          size="large"
          variant="outlined"
          className={classes.login}
          component={Link}
          to={{
            pathname: '/login',
            state: { background: location },
          }}
        >
          登入
        </Button>
      )}
    </Page>
  );
}

export default Home;
