import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { ThemeProvider, makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import ArrowDown from '@material-ui/icons/KeyboardArrowDown';
import AuthContext from '../../Auth/context';
import { Page } from './Screen';
import AlbumCover from '../../../components/AlbumCover';
import MultipleAccountMenu from '../../../components/MultipleAccountMenu';
import { lightTheme } from '../../../theme';
import Logo from './assets/logo.png';

const useStyles = makeStyles(theme => ({
  page: {
    backgroundColor: 'white',
    color: '#161853',
    position: 'relative',
  },
  logo: {
    width: 50,
    height: 50,
    marginBottom: theme.spacing(2),
  },
  title: {
    fontWeight: 'bold',
    textAlign: 'center',
    fontSize: 24,
    marginBottom: theme.spacing(2),
  },
  description: {
    textAlign: 'center',
    marginBottom: theme.spacing(6),
  },
  start: {
    width: 200,
    marginBottom: theme.spacing(2),
  },
  meWrapper: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: theme.spacing(2),
  },
  name: {
    fontWeight: 'bold',
    marginLeft: theme.spacing(2),
    maxWidth: 240,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  meContainer: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: theme.spacing(1),
  },
  login: {
    width: 200,
  },
  creditContainer: {
    position: 'absolute',
    bottom: theme.spacing(1),
    right: theme.spacing(1),
  },
  credit: {
    fontSize: 6,
  },
}));

function Home({ data, loading, fullpageApi }) {
  const classes = useStyles();
  const location = useLocation();
  const auth = React.useContext(AuthContext);

  const start = React.useCallback(() => {
    fullpageApi.moveSectionDown();
  }, [fullpageApi]);

  return (
    <ThemeProvider theme={lightTheme}>
      <Page className={classes.page}>
        <img className={classes.logo} src={Logo} alt="logo" />
        <Typography className={classes.title}>
          <FormattedMessage id="rewind.2021.home.title" />
        </Typography>
        <Typography className={classes.description}>
          <FormattedMessage id="rewind.2021.home.description" />
        </Typography>
        {data && data.me ? (
          <>
            <div className={classes.meWrapper}>
              <div className={classes.meContainer}>
                <AlbumCover
                  imageUrl={data.me.avatar}
                  createdAt={data.me.createdAt}
                  className={classes.avatar}
                  alt={data.me.name}
                />
                <Typography className={classes.name}>{data.me.name}</Typography>
              </div>
              <MultipleAccountMenu>
                {openMenu => (
                  <IconButton size="small" onClick={openMenu}>
                    <ArrowDown />
                  </IconButton>
                )}
              </MultipleAccountMenu>
            </div>
            <Button
              size="large"
              variant="contained"
              className={classes.start}
              onClick={start}
            >
              <FormattedMessage id="rewind.2021.home.start" />
            </Button>
            <Button
              size="small"
              className={classes.logout}
              onClick={auth.handleLogout}
            >
              <FormattedMessage id="auth.logout" />
            </Button>
          </>
        ) : loading ? (
          <CircularProgress />
        ) : (
          <Button
            size="large"
            variant="outlined"
            className={classes.login}
            component={Link}
            to={{
              pathname: '/login',
              state: { background: location },
            }}
          >
            <FormattedMessage id="auth.login" />
          </Button>
        )}
        <div className={classes.creditContainer}>
          <Typography
            variant="caption"
            component="p"
            className={classes.credit}
          >
            Frosty illustrations by Iconfinder
          </Typography>
          <Typography
            variant="caption"
            component="p"
            className={classes.credit}
          >
            Rockin’ around illustrations by Kalash
          </Typography>
          <Typography
            variant="caption"
            component="p"
            className={classes.credit}
          >
            Merry illustrations by Iconfinder
          </Typography>
        </div>
      </Page>
    </ThemeProvider>
  );
}

export default Home;
