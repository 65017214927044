import { useQuery } from '@apollo/react-hooks';
import { GetShowInfo } from '../v2/show';
import { SearchEpisode } from '../v2/episode';
import usePagination from '../../hooks/usePagination';
import { transformArray, transformShow, transformEpisode } from '../transform';

export function useGetShowInfo(showId) {
  const { data, ...args } = useQuery(GetShowInfo, {
    variables: { showId },
  });

  if (!data) {
    return args;
  }

  return {
    data: {
      userInfo: transformShow(data.playerShowFindOneByUrlSlug),
    },
    ...args,
  };
}

export function useGetShowEpisodes(showId) {
  const { data, ...args } = usePagination({
    dataKey: 'playerEpisodeFind',
    query: SearchEpisode,
    variables: { showId },
    ssr: false,
  });

  if (!data) {
    return args;
  }

  return {
    data: {
      feeds: transformArray(data.playerEpisodeFind, transformEpisode),
    },
    ...args,
  };
}

export function useGetShowData(showId) {
  const { loading: showLoading, data: showData } = useGetShowInfo(showId);
  const { data: episodeData } = useGetShowEpisodes(showId);

  const data = {
    ...showData,
    ...episodeData,
  };

  return {
    loading: showLoading,
    data,
  };
}
