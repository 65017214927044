import React from 'react';

function useAnimationFrame(callback, enabled) {
  const savedCallback = React.useRef();

  React.useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  React.useEffect(() => {
    let id;
    function tick() {
      savedCallback.current();
      id = requestAnimationFrame(tick);
    }
    if (enabled) {
      id = requestAnimationFrame(tick);
      return () => cancelAnimationFrame(id);
    }
  }, [enabled]);
}

export default useAnimationFrame;
