import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MuiSnackbar from '@material-ui/core/Snackbar';
import MuiSnackbarContent from '@material-ui/core/SnackbarContent';
import { green } from '@material-ui/core/colors';

const useStyles = makeStyles(theme => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
}));

function Snackbar({
  open,
  onClose,
  variant = 'info',
  message,
  vertical = 'bottom',
  horizontal = 'left',
}) {
  const classes = useStyles();

  return (
    <MuiSnackbar
      anchorOrigin={{
        vertical,
        horizontal,
      }}
      open={open}
      autoHideDuration={3000}
      onClose={onClose}
    >
      <MuiSnackbarContent
        className={classes[variant]}
        aria-describedby="snack-bar-message"
        message={<span id="snack-bar-message">{message}</span>}
      />
    </MuiSnackbar>
  );
}

export default Snackbar;
