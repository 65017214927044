import React from 'react';
import { Helmet } from 'react-helmet-async';
import config from '../../../config';
import { getPlatformUrl } from '../../../utils/distribution';

function FlinkHeader({ type, data }) {
  if (!data) {
    return null;
  }

  const { aggregateRating, tags } = data;

  const _title = type === 'USER' ? data.name : data.title;
  const title = `${_title} Podcast Platforms - Flink by Firstory`;

  const _d = type === 'USER' ? data.intro : data.description;
  const description = _d + '\n Podcast powered by Firstory.';

  const user = type === 'USER' ? data : data.author;
  const lang = (user.speakLanguage || 'ZH').toLowerCase();

  const imageUrl =
    data.ogImageUrl ||
    (type === 'USER' ? data.avatar : data.imageUrl || data.author.avatar);

  const playerUrl =
    type === 'USER'
      ? `${config.url}/user/${data.urlSlug || data.id}/platforms`
      : `${config.url}/story/${data.id}/platforms`;

  const oembedUrl = `${config.url}/oembed?url=${encodeURIComponent(playerUrl)}`;
  const canonicalUrl = playerUrl;

  const platforms = [
    'firstory',
    'kkbox',
    'spotify',
    'apple',
    'google',
    'pocketcast',
  ].map((platform, i) => ({
    '@type': 'ListItem',
    position: i + 1,
    url: getPlatformUrl(platform, data, type),
  }));

  const schemaData = {
    '@context': 'https://schema.org',
    '@type': 'ItemList',
    itemListElement: platforms,
  };

  if (
    aggregateRating &&
    aggregateRating.ratingValue > 0 &&
    aggregateRating.ratingCount > 0
  ) {
    schemaData.aggregateRating = {
      '@type': 'AggregateRating',
      ratingValue: aggregateRating.ratingValue,
      ratingCount: aggregateRating.ratingCount,
      bestRating: '5',
      worstRating: '1',
    };
  }

  if (tags && tags.length > 0) {
    schemaData.keywords = tags.map(t => t.tag).join(', ');
  }

  return (
    <Helmet>
      <html lang={lang} />
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="music.song" />
      {imageUrl && <meta property="og:image" content={imageUrl} />}
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:url" content={canonicalUrl} />
      <meta property="og:site_name " content={title} />
      <meta property="og:audio:type" content="audio/vnd.facebook.bridge" />
      <link rel="canonical" href={canonicalUrl} />
      <link
        rel="alternate"
        type="application/json+oembed"
        href={oembedUrl}
        title={title}
      />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@firstorylab" />
      <meta name="twitter:url" content={canonicalUrl} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      {imageUrl && <meta name="twitter:image" content={imageUrl} />}
      <script name="schema:podcast-show" type="application/ld+json">
        {JSON.stringify(schemaData)}
      </script>
    </Helmet>
  );
}

export default FlinkHeader;
