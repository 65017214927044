import React from 'react';
import { FormattedMessage } from 'react-intl';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import { Page } from './Screen';

const useStyles = makeStyles(theme => ({
  page: {
    color: '#F8B272',
    background: '#6D447E',
    padding: theme.spacing(4),
  },
  title: {
    textAlign: 'center',
    marginBottom: theme.spacing(4),
  },
  dayContainer: {
    border: '2px solid #F8B272',
    borderRadius: 10,
    padding: theme.spacing(2),
    margin: theme.spacing(2),
  },
  day: {
    textAlign: 'center',
    fontSize: 28,
    fontWeight: 'bold',
  },
  description: {
    textAlign: 'center',
    whiteSpace: 'pre-wrap',
    marginBottom: theme.spacing(6),
  },
}));

function Lifetime({ data }) {
  const classes = useStyles();

  const createdAt = React.useMemo(() => {
    return new Date(data.me.rewind.firstRecordCreatedAt);
  }, [data]);

  const diffDays = React.useMemo(() => {
    const now = new Date();
    return differenceInCalendarDays(now, createdAt);
  }, [createdAt]);

  return (
    <Page className={classes.page}>
      <Typography className={classes.title}>
        <FormattedMessage
          id="rewind.2021.lifetime.title"
          values={{
            day: () => (
              <div className={classes.dayContainer}>
                <Typography className={classes.day}>{diffDays}</Typography>
                <FormattedMessage id="rewind.2021.lifetime.day" />
              </div>
            ),
          }}
        />
      </Typography>
      <Typography className={classes.description} variant="body2">
        <FormattedMessage
          id="rewind.2021.lifetime.firstEpisode"
          values={{
            date: createdAt.toISOString().substr(0, 10),
          }}
        />
      </Typography>
      <Typography className={classes.description}>
        <FormattedMessage
          id={
            data.me.createdAt >= '2021-01-01'
              ? 'rewind.2021.lifetime.firstYear'
              : 'rewind.2021.lifetime.manyYear'
          }
        />
      </Typography>
    </Page>
  );
}

export default Lifetime;
