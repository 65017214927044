import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Page } from './Screen';
import Title from './Title';
import Image from './Image';
import image from './assets/top-day.svg';

function TopDay({ data, bgImage }) {
  const { date } = React.useMemo(() => {
    const { starts } = data.me.analyticData.dataByDate;
    let maxDate = starts[0].date;
    let maxValue = starts[0].value;
    starts.forEach(s => {
      if (s.value > maxValue) {
        maxDate = s.date;
        maxValue = s.value;
      }
    });
    return {
      date: maxDate,
      value: maxValue,
    };
  }, [data]);
  return (
    <Page bgImage={bgImage}>
      <Title variant="h4">{date}</Title>
      <Typography>是今年收聽量最高的一天！</Typography>
      <Image src={image} />
      <Typography variant="body2">想不到吧！</Typography>
    </Page>
  );
}

export default TopDay;
