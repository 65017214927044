import React from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import { ThemeProvider, makeStyles } from '@material-ui/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import loadable from '@loadable/component';
import PodcastDistribution from './pages/Distribution';
import Rewind2020 from './pages/Rewind/2020';
import Rewind2021 from './pages/Rewind/2021';
import { AlertProvider } from './components/Alert';
import ErrorBoundary from './components/ErrorBoundary';
import OneLink from './components/OneLink';
import RedirectWithStatus from './components/RedirectWithStatus';
import { lightTheme, darkTheme } from './theme';
import { loadHotjar } from './utils';
import { defaultLang, messages } from './i18n';
import { LangContext } from './i18n/LangProvider';

const Auth = loadable(() => import('./pages/Auth'));
const WebPlayer = loadable(() => import('./pages/WebPlayer'));
const UserSite = loadable(() => import('./pages/UserSite'));

const rootStyle = {
  height: '100%',
  width: '100%',
  margin: 0,
  padding: 0,
  overflowX: 'hidden',
  backgroundColor: 'transparent',
};

const useStyles = makeStyles({
  '@global': {
    html: rootStyle,
    body: rootStyle,
    '#root': rootStyle,
    a: {
      textDecoration: 'none',
    },
  },
});

function Main({ subdomain }) {
  useStyles();
  const { lang } = React.useContext(LangContext);
  const location = useLocation();
  const background = location.state && location.state.background;
  const isWebPlayer = !subdomain || subdomain === 'open';
  let userUrlSlug = subdomain;
  if (subdomain === 'lab') {
    userUrlSlug = 'firstorylab';
  }

  React.useEffect(() => {
    loadHotjar();
  }, []);

  const authRoute = (
    <Route path={['/login', '/signup', '/password-reset']}>
      <Auth subdomain={subdomain} />
    </Route>
  );

  if (
    subdomain === 'firstorylab' ||
    subdomain === 'cjyqorlaf2pg20743imp927zg'
  ) {
    const redirectUrl = `https://lab.firstory.io${location.pathname}`;
    return <RedirectWithStatus isExternal to={redirectUrl} xstatus={301} />;
  }

  return (
    <IntlProvider
      locale={lang}
      messages={messages[lang]}
      defaultLocale={defaultLang}
    >
      <ThemeProvider theme={isWebPlayer ? darkTheme : lightTheme}>
        <AlertProvider>
          <ErrorBoundary>
            <CssBaseline />
            <Switch location={background || location}>
              <Route path="/store" component={OneLink} />
              {authRoute}
              <Route
                path={['/user/:userId/platforms', '/story/:recordId/platforms']}
              >
                <PodcastDistribution />
              </Route>
              <Route path="/rewind/2020">
                <Rewind2020 />
              </Route>
              <Route path="/rewind/2021">
                <Rewind2021 />
              </Route>
              <Route>
                {isWebPlayer ? (
                  <WebPlayer />
                ) : (
                  userUrlSlug !== 'auth' && (
                    <UserSite userUrlSlug={userUrlSlug} />
                  )
                )}
              </Route>
            </Switch>
            {background && authRoute}
          </ErrorBoundary>
        </AlertProvider>
      </ThemeProvider>
    </IntlProvider>
  );
}

export default Main;
