import config from '../config';

export function loadScript(name, url) {
  if (!window.loadScriptPromise) {
    window.loadScriptPromise = {};
  }

  if (window.loadScriptPromise[name]) {
    return window.loadScriptPromise[name];
  }

  const p = new Promise(resolve => {
    const existingScript = document.getElementById(name);
    if (existingScript) {
      resolve();
    } else {
      const script = document.createElement('script');
      script.src = url;
      script.id = name;
      document.body.appendChild(script);

      script.onload = () => {
        resolve();
      };
    }
  });

  window.loadScriptPromise[name] = p;
  return p;
}

export async function loadFbSdk() {
  if (window.fbSdkLoaded) {
    return window.FB;
  }
  await loadScript('fb-sdk', 'https://connect.facebook.net/en_US/sdk.js');
  return new Promise(resolve => {
    window.fbAsyncInit = () => {
      window.FB.init({
        appId: config.fbAppId,
        autoLogAppEvents: true,
        xfbml: true,
        version: 'v4.0',
      });
      window.fbSdkLoaded = true;
      resolve(window.FB);
    };
  });
}

export async function loadMixpanelSdk() {
  const mixpanel = (await import('mixpanel-browser')).default;
  if (!window.mixpanelSdkLoaded) {
    if (!window.mixpanelInitPromise) {
      window.mixpanelInitPromise = new Promise(resolve => {
        mixpanel.init(config.mixpanelToken, {
          loaded() {
            window.mixpanelSdkLoaded = true;
            resolve(mixpanel);
          },
        });
      });
    }
    return window.mixpanelInitPromise;
  }
  return mixpanel;
}

export function loadHotjar() {
  if (!config.hotjarId) {
    return;
  }
  (function (h, o, t, j, a, r) {
    h.hj =
      h.hj ||
      function () {
        (h.hj.q = h.hj.q || []).push(arguments);
      };
    h._hjSettings = { hjid: config.hotjarId, hjsv: 6 };
    a = o.getElementsByTagName('head')[0];
    r = o.createElement('script');
    r.async = 1;
    r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
    a.appendChild(r);
  })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
}
