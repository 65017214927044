import xss from 'xss';
import _formatISODuration from 'date-fns/formatISODuration';

export function imageproxy(src, options) {
  const gcsPrefix =
    'https://storage.googleapis.com/firstory-709db.appspot.com/';
  const cdnPrefix = 'https://d3mww1g1pfq2pt.cloudfront.net/';
  if (!src) {
    return src;
  }
  if (src.startsWith(gcsPrefix)) {
    const path = src.replace(gcsPrefix, cdnPrefix);
    return path;
    // return `https://imgcdn.firstory.me/${options}/${path}`;
  }
  return src;
}

function padZero(num) {
  if (num < 10) {
    return `0${num}`;
  }
  return num;
}

export function formatISODuration(ms) {
  const s = Math.floor(ms / 1000);
  const hours = Math.floor(s / 3600);
  const minutes = Math.floor((s - hours * 3600) / 60);
  const seconds = Math.floor(s % 60);

  return _formatISODuration({
    hours,
    minutes,
    seconds,
  });
}

export function getMMSSfromSeconds(time) {
  const minutes = Math.floor(time / 60);
  const seconds = Math.floor(time % 60);
  return `${padZero(minutes)}:${padZero(seconds)}`;
}

export function isSameTrack(a, b) {
  if (a === b) {
    return true;
  }
  if (!a || !b) {
    return false;
  }
  return a.url === b.url;
}

export function checkHasPermission(roles, permission) {
  for (let i = 0; i < roles.length; i++) {
    const hasPermission = roles[i].permissions.find(p => p.name === permission);
    if (hasPermission) {
      return true;
    }
  }
  return false;
}
export function countryCodeToEmoji(cc) {
  const CC_REGEX = /^[a-z]{2}$/i;
  const OFFSET = 127397;
  if (!CC_REGEX.test(cc)) {
    return null;
  }
  const chars = [...cc.toUpperCase()].map(c => c.charCodeAt() + OFFSET);
  return String.fromCodePoint(...chars);
}

export function filterXss(str) {
  const options = {
    whiteList: {
      div: [],
      a: ['href', 'target'],
      p: [],
      b: [],
      span: [],
      strong: [],
      em: [],
      br: [],
      ul: [],
      ol: [],
      li: [],
    },
    stripIgnoreTag: true,
    stripIgnoreTagBody: ['style', 'script'],
  };
  const html = xss(str, options);
  return html.trim();
}

export function hasUserRole(roles, name) {
  if (!roles) {
    return false;
  }
  return roles.some(r => r.name === name);
}

export * from './loadScript';
export { default as customFetch } from './customFetch';
