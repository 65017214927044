import React from 'react';
import cx from 'classnames';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import { colors } from '../../../constants';

const useStyles = makeStyles(theme => ({
  title: {
    color: colors.rewindYellow,
    fontWeight: 'bold',
  },
}));

function Title({ className, ...props }) {
  const classes = useStyles();
  return (
    <Typography
      variant="h2"
      className={cx(className, classes.title)}
      {...props}
    />
  );
}

export default Title;
