import React from 'react';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import { Page } from './Screen';
import Title from './Title';
import Image from './Image';
import image from './assets/lifetime.svg';
import logo from './assets/logo.png';

const useStyles = makeStyles(theme => ({
  logo: {
    width: theme.spacing(6),
    height: theme.spacing(6),
    marginBottom: theme.spacing(2),
  },
  last: {
    marginTop: theme.spacing(2),
    fontWeight: 'bold',
  },
}));

function Lifetime({ data, bgImage }) {
  const classes = useStyles();

  const diffDays = React.useMemo(() => {
    const now = new Date();
    const createdAt = new Date(data.me.createdAt);
    return differenceInCalendarDays(now, createdAt);
  }, [data]);

  return (
    <Page bgImage={bgImage}>
      <img src={logo} alt="Firstory" className={classes.logo} />
      <Typography>距離你的節目創建已經經過</Typography>
      <Title>{diffDays}</Title>
      <Typography>天</Typography>
      <Image src={image} />
      <Typography variant="body2">謝謝你陪伴我們走過 2020</Typography>
      <Typography variant="body2">祝你聖誕快樂和新年快樂！</Typography>
      <Typography variant="h6" className={classes.last}>
        2021 讓我們再一起努力吧！
      </Typography>
    </Page>
  );
}

export default Lifetime;
