const Configs = {
  dev: {
    env: 'dev',
    url: 'http://localhost:8080',
    endpoints: {
      // auth: 'http://localhost:8080/api',
      // simple: 'http://localhost:3030/v1/graphql',
      simple: 'https://prod-api.firstory.me/v1/graphql',
      v1: 'https://backend.endpoints.firstory-709db.cloud.goog',
      server: 'https://prod-api.firstory.me/v1/graphql',
      // server: 'https://backend.endpoints.firstory-709db.cloud.goog',
    },
    firebase: {
      apiKey: 'AIzaSyANhw7kEbm7_CS1l1cEZybYeFb7EdDFuI4',
      authDomain: 'firstory-dev-5a8e8.firebaseapp.com',
      databaseURL: 'https://firstory-dev-5a8e8.firebaseio.com',
      projectId: 'firstory-dev-5a8e8',
      storageBucket: 'firstory-dev-5a8e8.appspot.com',
      messagingSenderId: '93689108236',
      dynamicLinkDomain: 'firstorydev.page.link',
    },
    tappay: {
      appId: '15370',
      appKey:
        'app_RVI9GzD1hDlgr51uVIOr6nbjLVCpilXJoE8uMF2nL3tXcSW2EedjaZrAS7bJ',
      serverType: 'sandbox',
    },
    googleMerchantId: 'sandbox',
    applePayMerchantId: 'merchant.com.firstory',
    ga: 'UA-113063265-3',
    fbAppId: 665576853857854,
    fbPixelId: '256901005598539',
    mixpanelToken: '99a374a10235aac74deea8c20c157dd2',
  },
  staging: {
    env: 'staging',
    url: 'https://firstory-webplayer-staging-dot-firstory-709db.uc.r.appspot.com',
    endpoints: {
      auth: 'https://firstory-webplayer-staging-dot-firstory-709db.uc.r.appspot.com/api',
      simple: 'https://api-staging.firstory.me',
      server: 'https://api-staging.firstory.me',
    },
    firebase: {
      apiKey: 'AIzaSyAhUYupQ-NV4okBivsxci49V4pi4CA3wxE',
      authDomain: 'firstory-709db.firebaseapp.com',
      databaseURL: 'https://firstory-709db.firebaseio.com',
      projectId: 'firstory-709db',
      storageBucket: 'firstory-709db.appspot.com',
      messagingSenderId: '1052149404106',
      dynamicLinkDomain: 'link.firstory.me',
    },
    tappay: {
      appId: '15370',
      appKey:
        'app_RVI9GzD1hDlgr51uVIOr6nbjLVCpilXJoE8uMF2nL3tXcSW2EedjaZrAS7bJ',
      serverType: 'sandbox',
    },
    googleMerchantId: 'sandbox',
    applePayMerchantId: 'merchant.com.firstory',
    ga: 'UA-113063265-3',
    fbAppId: 1373748122771244,
    fbPixelId: '256901005598539',
    mixpanelToken: '99a374a10235aac74deea8c20c157dd2',
    hotjarId: 1667336,
  },
  prod: {
    env: 'prod',
    url: 'https://open.firstory.me',
    endpoints: {
      auth: 'https://open.firstory.me/api',
      simple: 'https://prod-api.firstory.me/v1/graphql',
      v1: 'https://backend.endpoints.firstory-709db.cloud.goog',
      server: 'https://prod-api.firstory.me/v1/graphql',
    },
    firebase: {
      apiKey: 'AIzaSyAhUYupQ-NV4okBivsxci49V4pi4CA3wxE',
      authDomain: 'firstory-709db.firebaseapp.com',
      databaseURL: 'https://firstory-709db.firebaseio.com',
      projectId: 'firstory-709db',
      storageBucket: 'firstory-709db.appspot.com',
      messagingSenderId: '1052149404106',
      dynamicLinkDomain: 'link.firstory.me',
    },
    tappay: {
      appId: '15370',
      appKey:
        'app_RVI9GzD1hDlgr51uVIOr6nbjLVCpilXJoE8uMF2nL3tXcSW2EedjaZrAS7bJ',
      serverType: 'production',
    },
    googleMerchantId: '13403442244508269400',
    applePayMerchantId: 'merchant.com.firstory',
    ga: 'UA-113063265-3',
    fbAppId: 1373748122771244,
    fbPixelId: '256901005598539',
    mixpanelToken: '1fa276e4e72e21867df7c429e861eecf',
  },
};

const config =
  process.env.NODE_ENV === 'development' ? Configs.dev : Configs.prod;

export default config;
