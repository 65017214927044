import React from 'react';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import { Page } from './Screen';
import CommentBg from './assets/comment-bg.svg';

const useStyles = makeStyles(theme => ({
  page: {
    color: '#A1F4C7',
    background: '#5945D4',
    textAlign: 'center',
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  description: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    whiteSpace: 'pre-line',
  },
  value: {
    fontSize: 32,
    fontWeight: 'bold',
  },
}));

function Feedback({ data, bgImage }) {
  const classes = useStyles();
  const count = data.me.rewind.feedbackCount;
  return (
    <Page className={classes.page}>
      <Typography className={classes.title}>
        <FormattedMessage id="rewind.2021.comment.title" />
      </Typography>
      <Typography className={classes.value}>{count}</Typography>
      <Typography className={classes.count}>
        <FormattedMessage id="rewind.2021.comment.count" />
      </Typography>
      <Typography className={classes.description}>
        <FormattedMessage
          id={
            count < 10
              ? 'rewind.2021.comment.low'
              : count < 30
              ? 'rewind.2021.comment.medium'
              : 'rewind.2021.comment.high'
          }
        />
      </Typography>
      <img alt="comment" src={CommentBg} className={classes.image} />
    </Page>
  );
}

export default Feedback;
