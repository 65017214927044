import React from 'react';
import { createMuiTheme } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
import Color from 'color';
import { colors } from './constants';

const typography = {
  fontFamily:
    '-apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif',
};

const overrides = {
  MuiCssBaseline: {},
  MuiTab: {
    root: {
      textTransform: 'none',
    },
  },
  MuiTypography: {
    h1: { fontWeight: 'bold' },
    h2: { fontWeight: 'bold' },
    h3: { fontWeight: 'bold' },
    h4: { fontWeight: 'bold' },
    h5: { fontWeight: 'bold' },
    h6: { fontWeight: 'bold' },
  },
  MuiButton: {
    root: {
      borderRadius: 10,
      textTransform: 'none',
    },
    contained: {
      boxShadow: 'none',
      '&:active': {
        boxShadow: 'none',
      },
      '&:hover': {
        boxShadow: 'none',
      },
      '&.Mui-focusVisible': {
        boxShadow: 'none',
      },
    },
  },
  MuiTableCell: {
    head: {
      fontWeight: 700,
    },
  },
};

const darkTheme = createMuiTheme({
  typography,
  palette: {
    primary: { main: colors.tomato },
    secondary: { main: blue.A100 },
    type: 'dark',
  },
  overrides,
});

const lightTheme = createMuiTheme({
  typography,
  palette: {
    primary: { main: colors.tomato },
    secondary: { main: colors.softBlue },
    type: 'light',
  },
  overrides,
});

function createCustomTheme({ colors }) {
  const backgroundColor = Color(colors[2]);
  const paperColor = backgroundColor.isLight()
    ? backgroundColor.lighten(0.1)
    : backgroundColor.darken(0.1);
  return createMuiTheme({
    typography,
    palette: {
      primary: { main: colors[0] },
      secondary: { main: colors[1] },
      background: {
        default: backgroundColor.toString(),
        paper: paperColor.toString(),
      },
      text: {
        primary: colors[0],
        secondary: Color(colors[0]).alpha(0.6).toString(),
        disabled: Color(colors[0]).alpha(0.3).toString(),
        hint: Color(colors[0]).alpha(0.2).toString(),
      },
      divider: Color(colors[0]).alpha(0.2).toString(),
      type: 'light',
    },
    overrides,
  });
}

function useCustomTheme({ websiteSetting }) {
  const theme = React.useMemo(() => {
    function getColor(name, defaultColor) {
      return websiteSetting && websiteSetting[name]
        ? websiteSetting[name]
        : defaultColor;
    }
    const themeHexFirst = getColor('themeHexFirst', '#141E61');
    const themeHexSecond = getColor('themeHexSecond', '#787A91');
    const themeHexThird = getColor('themeHexThird', '#EEEEEE');

    const theme = createCustomTheme({
      colors: [themeHexFirst, themeHexSecond, themeHexThird],
    });

    return theme;
  }, [websiteSetting]);

  return theme;
}

export { darkTheme, lightTheme, createCustomTheme, useCustomTheme };
