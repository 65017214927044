import React from 'react';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { Page } from './Screen';
import Title from './assets/end-title.svg';
import Image from './assets/end-image.svg';

const useStyles = makeStyles(theme => ({
  page: {
    color: 'white',
    background: '#A6495F',
    position: 'relative',
  },
  title: {
    marginBottom: theme.spacing(4),
  },
  description: {
    textAlign: 'center',
    whiteSpace: 'pre-line',
    marginBottom: theme.spacing(8),
  },
  promo: {
    fontWeight: 'bold',
    marginTop: theme.spacing(2),
  },
  image: {
    position: 'absolute',
    bottom: -100,
    right: -80,
    height: 280,
    width: 280,
  },
}));

function Benchmark({ data }) {
  const classes = useStyles();

  return (
    <Page className={classes.page}>
      <img src={Title} alt="title" className={classes.title} />
      <Typography className={classes.description} variant="body2">
        <FormattedMessage
          id="rewind.2021.end.description"
          values={{
            bold: chunk => (
              <Typography className={classes.promo}>{chunk}</Typography>
            ),
            twitter: chunk => (
              <Link
                color="textPrimary"
                target="_blank"
                rel="noreferrer noopener"
                href="https://twitter.com/firstoryhq"
              >
                <b>{chunk}</b>
              </Link>
            ),
          }}
        />
      </Typography>
      <img src={Image} alt="end" className={classes.image} />
    </Page>
  );
}

export default Benchmark;
