import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Page } from './Screen';
import Title from './Title';
import Image from './Image';
import image from './assets/gender.svg';

function Gender({ data, bgImage }) {
  const { total, maxGenderAge } = React.useMemo(() => {
    const { age } = data.me.analyticData.aggregate;
    let total = 0;
    const arr = [];
    age.forEach(a => {
      ['MALE', 'FEMALE', 'NON_BINARY', 'NOT_SPECIFIED'].forEach(g => {
        total += a.counts[g];
        arr.push({
          age: a.age,
          gender: g,
          value: a.counts[g],
        });
      });
    });
    arr.sort((a, b) => b.value - a.value);
    return {
      maxGenderAge: arr[0],
      total,
    };
  }, [data]);

  if (!maxGenderAge) {
    return null;
  }

  const genderText = {
    MALE: '男性',
    FEMALE: '女性',
    NON_BINARY: '聽眾',
    NOT_SPECIFIED: '聽眾',
  };

  return (
    <Page bgImage={bgImage}>
      <Typography>你的聽眾有</Typography>
      <Title>{((maxGenderAge.value / total) * 100).toFixed(1)}%</Title>
      <Typography>
        是 {maxGenderAge.age} 歲{genderText[maxGenderAge.gender]}
      </Typography>
      <Image src={image} />
      <Typography variant="body2">跟有原本預想的一樣嗎？</Typography>
      <Typography variant="body2">多跟聽眾互動可以幫助節目成長喔！</Typography>
    </Page>
  );
}

export default Gender;
