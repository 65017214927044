import React from 'react';
import { Route, Redirect } from 'react-router-dom';

function ExternalRedirect({ to }) {
  React.useEffect(() => {
    if (window.location) {
      window.location.replace(to);
    }
  }, [to]);

  return null;
}

function RedirectWithStatus({ isExternal, to, status }) {
  return (
    <Route
      render={({ staticContext }) => {
        if (staticContext) {
          if (isExternal) {
            staticContext.url = to;
          }
          staticContext.status = status;
        }
        return isExternal ? <ExternalRedirect to={to} /> : <Redirect to={to} />;
      }}
    />
  );
}

export default RedirectWithStatus;
