import React from 'react';
import { Route } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Logo from './Logo';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    height: '100%',
    minHeight: 300,
  },
  logo: {
    width: 50,
    height: 50,
    marginBottom: theme.spacing(4),
  },
  title: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(4),
    padding: theme.spacing(4, 6),
  },
  content: {
    marginBottom: theme.spacing(4),
  },
}));

function NotFound({ children }) {
  const classes = useStyles();

  return (
    <Route
      render={({ staticContext }) => {
        if (staticContext) {
          staticContext.status = 404;
        }
        return (
          <div className={classes.container}>
            <a href="https://firstory.me">
              <Logo className={classes.logo} />
            </a>
            <Typography
              variant="h5"
              color="textPrimary"
              className={classes.title}
            >
              <FormattedMessage id="notFound.title" />
            </Typography>
            <Container maxWidth="sm">
              <Paper variant="outlined" className={classes.paper}>
                <Typography
                  variant="h5"
                  color="textPrimary"
                  className={classes.title}
                >
                  <FormattedMessage id="general.learnMore" />
                </Typography>
                <Typography className={classes.content}>
                  <FormattedMessage
                    id="notFound.audience"
                    values={{
                      link: chunk => (
                        <Link color="secondary" href="https://open.firstory.me">
                          {chunk}
                        </Link>
                      ),
                    }}
                  />
                  <br />
                  <FormattedMessage
                    id="notFound.podcaster"
                    values={{
                      link: chunk => (
                        <Link
                          color="secondary"
                          href="https://studio.firstory.me"
                        >
                          {chunk}
                        </Link>
                      ),
                    }}
                  />
                </Typography>
              </Paper>
            </Container>
          </div>
        );
      }}
    />
  );
}

export default NotFound;
