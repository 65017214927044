import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';
import ReactFullpage from '@fullpage/react-fullpage';
import { useQuery } from '@apollo/react-hooks';
import { ThemeProvider, makeStyles } from '@material-ui/styles';
import Screen from './Screen';
import Home from './Home';
import Lifetime from './Lifetime';
import Duration from './Duration';
import TopEpisode from './TopEpisode';
import Music from './Music';
import Audience from './Audience';
import Feedback from './Feedback';
import Donation from './Donation';
import Benchmark from './Benchmark';
import End from './End';
import background from './assets/background.svg';
import { GetRewind2021Data } from '../../../gql/queries';
import { darkTheme } from '../../../theme';
import { TrackerProvider } from '../../../analytics/tracker';

const useStyles = makeStyles(theme => ({
  '@global': {
    '#fp-nav ul li a span, .fp-slidesNav ul li a span': {
      backgroundColor: theme.palette.text.primary,
    },
  },
  container: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    background: `url(${background})`,
  },
}));

function Rewind() {
  const { loading, data } = useQuery(GetRewind2021Data);

  if (loading || !data || !data.me) {
    return <Home data={data} loading={loading} />;
  }

  const { location } = data.me.account;
  const showMusicLocations = {
    TW: true,
    HK: true,
    MO: true,
    MY: true,
    SG: true,
  };
  const showDonationLocations = {
    TW: true,
  };
  const showMusic = showMusicLocations[location];
  const showDonation = showDonationLocations[location];

  return (
    <ReactFullpage
      navigation
      navigationPosition="right"
      scrollingSpeed={1000}
      verticalCentered={false}
      sectionsColor={[
        '#6D447E',
        '#6D447E',
        '#5945D4',
        ...(showMusic ? ['#5945D4'] : []),
        '#5945D4',
        '#5945D4',
        ...(showDonation ? ['#62AFB4'] : []),
        '#3F8D47',
        '#A6495F',
      ]}
      render={({ state, fullpageApi }) => {
        return (
          <ReactFullpage.Wrapper>
            <Home data={data} fullpageApi={fullpageApi} />
            <Lifetime data={data} />
            <Duration data={data} />
            <TopEpisode data={data} />
            {showMusic && <Music data={data} />}
            <Audience data={data} />
            <Feedback data={data} />
            {showDonation && <Donation data={data} />}
            <Benchmark data={data} />
            <End data={data} />
          </ReactFullpage.Wrapper>
        );
      }}
    />
  );
}

function RewindPage() {
  const classes = useStyles();
  const intl = useIntl();
  return (
    <TrackerProvider>
      <ThemeProvider theme={darkTheme}>
        <div className={classes.container}>
          <Helmet>
            <title>
              {intl
                .formatMessage({ id: 'rewind.2021.home.title' })
                .replace('\n', '')}
            </title>
            <meta
              name="description"
              content={intl
                .formatMessage({ id: 'rewind.2021.home.title' })
                .replace('\n', '')}
            />
          </Helmet>
          <Screen>
            <Rewind />
          </Screen>
        </div>
      </ThemeProvider>
    </TrackerProvider>
  );
}

export default RewindPage;
