import React from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ArrowDown from '@material-ui/icons/KeyboardArrowDown';
import MultipleAccountMenu from '../../components/MultipleAccountMenu';
import { useGetMe } from '../../gql/hooks/user';
import AuthContext from '../Auth/context';

const useStyles = makeStyles({
  loginContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  button: { width: '75%', marginBottom: 8 },
  logoutButton: {
    marginLeft: 8,
    marginBottom: 8,
  },
  userRow: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    padding: 20,
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
    },
  },
  userInfo: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    paddingRight: 8,
  },
  avatar: {
    width: 30,
    height: 30,
    borderRadius: 15,
    objectFit: 'cover',
  },
  name: {
    paddingLeft: 8,
    fontWeight: 'bold',
    color: 'white',
  },
  email: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

function NavbarAuth() {
  const classes = useStyles();
  const { loading, data } = useGetMe();
  const location = useLocation();
  const auth = React.useContext(AuthContext);

  if (loading) {
    return null;
  }

  if (data && data.me) {
    return (
      <div>
        <MultipleAccountMenu>
          {openMenu => (
            <List dense>
              <ListItem button component={RouterLink} to="/user/me">
                <ListItemAvatar>
                  <Avatar variant="rounded" src={data.me.avatar} />
                </ListItemAvatar>
                <ListItemText
                  primary={data.me.name}
                  secondary={data.me.account.email}
                  secondaryTypographyProps={{ className: classes.email }}
                />
                <ListItemSecondaryAction>
                  <IconButton edge="end" size="small" onClick={openMenu}>
                    <ArrowDown />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            </List>
          )}
        </MultipleAccountMenu>
        <Button className={classes.logoutButton} onClick={auth.handleLogout}>
          <FormattedMessage id="auth.logout" />
        </Button>
      </div>
    );
  }

  return (
    <div className={classes.loginContainer}>
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        component={RouterLink}
        to={{
          pathname: '/signup',
          state: { background: location },
        }}
      >
        <FormattedMessage id="auth.signup" />
      </Button>
      <Button
        className={classes.button}
        component={RouterLink}
        to={{
          pathname: '/login',
          state: { background: location },
        }}
      >
        <FormattedMessage id="auth.login" />
      </Button>
    </div>
  );
}

export default NavbarAuth;
