import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { GetMe } from '../v2/user';
import { transformArray, transformShow } from '../transform';
import AuthContext from '../../pages/Auth/context';

export function useGetMe() {
  const { token } = React.useContext(AuthContext);
  const { data, ...args } = useQuery(GetMe, {
    skip: !token,
  });

  if (!data) {
    return args;
  }

  return {
    data: {
      me: {
        ...transformShow(data.studioShowFindOne),
        account: data.studioUserFindOne,
      },
      multipleAccounts: transformArray(
        data.studioShowFind,
        transformShow,
      ).filter(s => !!s.name),
    },
  };
}
