import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import { Page } from './Screen';
import bgImage from './assets/audience-bg.svg';

const useStyles = makeStyles(theme => ({
  page: {
    color: '#A1F4C7',
    textAlign: 'center',
  },
  title: {
    marginBottom: theme.spacing(4),
  },
  item: {
    marginTop: theme.spacing(4),
  },
  itemTitle: {
    marginBottom: theme.spacing(2),
  },
  itemValue: {
    fontSize: 32,
    fontWeight: 'bold',
  },
}));

function Country({ data }) {
  const classes = useStyles();
  const intl = useIntl();
  const { country } = data.me.analyticData.aggregate;

  const { maxGender, maxAge } = React.useMemo(() => {
    const { age } = data.me.analyticData.aggregate;
    const arr = [];
    age.forEach(a => {
      ['MALE', 'FEMALE', 'NON_BINARY', 'NOT_SPECIFIED'].forEach(g => {
        arr.push({
          age: a.age,
          gender: g,
          value: a.counts[g],
        });
      });
    });
    arr.sort((a, b) => b.value - a.value);
    return {
      maxAge: arr[0].age,
      maxGender: arr[0].MALE > arr[0].FEMALE ? 'MALE' : 'FEMALE',
    };
  }, [data]);

  const genderTextIds = {
    MALE: 'rewind.2021.audience.gender.male',
    FEMALE: 'rewind.2021.audience.gender.female',
  };

  const topHourId = React.useMemo(() => {
    const d = data.me.analyticData.aggregate.hourlyUniqueStarts;
    let offsetData = d;
    const timezoneOffsetHour = Math.floor(new Date().getTimezoneOffset() / 60);
    if (timezoneOffsetHour > 0) {
      offsetData = [
        ...d.slice(timezoneOffsetHour),
        ...d.slice(0, timezoneOffsetHour),
      ];
    } else if (timezoneOffsetHour < 0) {
      offsetData = [
        ...d.slice(d.length + timezoneOffsetHour),
        ...d.slice(0, d.length + timezoneOffsetHour),
      ];
    }
    let topHour = 0;
    let maxValue = 0;
    offsetData.forEach((d, i) => {
      if (d > maxValue) {
        maxValue = d;
        topHour = i;
      }
    });
    const day = Math.floor(topHour / 24);
    const hour = topHour % 24;

    const hourTextIds = [
      'rewind.2021.audience.weekend.midnight',
      'rewind.2021.audience.weekend.morning',
      'rewind.2021.audience.weekend.afternoon',
      'rewind.2021.audience.weekend.night',
      'rewind.2021.audience.weekday.midnight',
      'rewind.2021.audience.weekday.morning',
      'rewind.2021.audience.weekday.afternoon',
      'rewind.2021.audience.weekday.night',
    ];

    const index = (day === 0 || day === 6 ? 0 : 4) + Math.floor(hour / 6);
    return hourTextIds[index];
  }, [data]);

  const arr = [
    {
      titleId: 'rewind.2021.audience.from',
      value: country.length,
      labelId: 'rewind.2021.audience.country',
    },
    {
      titleId: 'rewind.2021.audience.gender',
      value: maxAge,
      labelId: genderTextIds[maxGender],
    },
    {
      titleId: 'rewind.2021.audience.listenAt',
      value: intl.formatMessage({ id: topHourId }),
      labelId: 'rewind.2021.audience.listen',
    },
  ];

  return (
    <Page className={classes.page} bgImage={bgImage}>
      <Typography className={classes.title}>
        <FormattedMessage id="rewind.2021.audience.title" />
      </Typography>
      {arr.map(a => (
        <div key={a.titleId} className={classes.item}>
          <Typography className={classes.itemTitle}>
            <FormattedMessage id={a.titleId} />
          </Typography>
          <Typography className={classes.itemValue}>{a.value}</Typography>
          {a.labelId && (
            <Typography className={classes.itemLabel}>
              <FormattedMessage id={a.labelId} />
            </Typography>
          )}
        </div>
      ))}
    </Page>
  );
}

export default Country;
