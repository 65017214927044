import gql from 'graphql-tag';

export const UserInfoFragment = gql`
  fragment UserInfoFragment on User {
    id
    deleted
    name
    avatar
    intro
    publicRecordCount
    isCreator
    authorName
    urlSlug
    speakLanguage
    explicit
    googlePodcastBlock
    isKkboxLimited
    podcastCategory {
      id
      textEn
      textZh
    }
    importRssUrl
    podcastDistribution {
      id
      spotifyId
      spotifyStatus
      applePodcastUrl
      applePodcastStatus
      googlePodcastUrl
      googlePodcastStatus
      pocketcastUrl
      pocketcastStatus
      kkboxUrl
      kkboxStatus
      soundonUrl
      soundonStatus
      breakerUrl
      breakerStatus
      playerFMUrl
      playerFMStatus
      stitcherUrl
      stitcherStatus
      castboxUrl
      castboxStatus
      overcastUrl
      overcastStatus
      castroUrl
      castroStatus
      podcastAddictUrl
      podcastAddictStatus
      radiopublicUrl
      radiopublicStatus
    }
    websiteSetting {
      id
      active
      gaTrackingId
      fbPixelId
      themeHexFirst
      themeHexSecond
      themeHexThird
      customDomain
      flinkShowDonate
      flinkShowExternal
      flinkShowRssFeed
      flinkShowPlatforms
      flinkShowComment
      flinkShowVoiceMail
      flinkShowDownloadAudioFile
      playerShowDonate
      playerShowRssFeed
      playerHideLogo
      playerShowShownote
      playerShowComment
      playerShowVoicemail
      playerShowDownloadAudioFile
    }
    flinkSetting {
      showDonation
      showSocialMedia
    }
    aggregateRating {
      ratingValue
      ratingCount
    }
    tags {
      tag
    }
    socialMedia {
      id
      facebookUrl
      instagramUrl
      twitterUrl
      youtubeUrl
      mediumUrl
      linkedInUrl
      customLinks {
        id
        title
        url
      }
    }
    donationSetting {
      id
      active
      title
      priceOnce
      allowCustom
      customPreset
      rankTitle
      showDonationRank
    }
  }
`;

export const RecordFragment = gql`
  fragment RecordFragment on Record {
    id
    title
    author {
      id
      name
      avatar
      isCreator
      urlSlug
      explicit
    }
    topic {
      id
      title
      imageUrl
    }
    url
    imageUrl
    createdAt
    duration
    description
    descriptionHtml
    explicit
    seasonNumber
    episodeNumber
    commentCount
    aggregateRating {
      ratingValue
      ratingCount
    }
    tags {
      tag
    }
  }
`;

export const CommentFragment = gql`
  fragment V1CommentContentFragment on Comment {
    id
    deleted
    author {
      id
      name
      avatar
      urlSlug
      deleted
      websiteSetting {
        id
        active
      }
    }
    type
    message
    url
    duration
    createdAt
    user {
      id
      name
    }
    record {
      id
      title
      imageUrl
      createdAt
      deleted
      author {
        id
        name
        deleted
      }
    }
  }
  fragment V1CommentFragment on Comment {
    ...V1CommentContentFragment
    comments {
      ...V1CommentContentFragment
    }
  }
`;
