import gql from 'graphql-tag';

export const WebsiteSettingFragment = gql`
  fragment WebsiteSettingFragment on WebsiteSetting {
    active
    gaTrackingId
    fbPixelId
    themeHexFirst
    themeHexSecond
    themeHexThird
    customDomain
    flinkShowDonate
    flinkShowExternal
    flinkShowRssFeed
    flinkShowPlatforms
    flinkShowComment
    flinkShowVoiceMail
    flinkShowDownloadAudioFile
    playerShowDonate
    playerShowRssFeed
    playerHideLogo
    playerShowShownote
    playerShowComment
    playerShowVoicemail
    playerShowDownloadAudioFile
  }
`;

export const ShowInfoFragment = gql`
  fragment ShowInfoFragment on Show {
    id
    name
    avatar
    intro
    isCreator
    author
    urlSlug
    language
    explicit
    categories {
      id
      nameEn
      nameZh
    }
    import {
      status
      originRssUrl
    }
    distributions {
      status
      platformType
      platformId
      platformUrl
    }
    externalLinks {
      title
      type
      url
    }
    websiteSetting {
      ...WebsiteSettingFragment
    }
  }
  ${WebsiteSettingFragment}
`;

export const DonationSettingFragment = gql`
  fragment DonationSettingFragment on DonationSetting {
    id
    active
    title
    priceOnce
    allowCustom
    customPreset
    rankTitle
    showDonationRank
  }
`;

export const EpisodeFragment = gql`
  fragment EpisodeFragment on Episode {
    id
    title
    show {
      id
      name
      avatar
      isCreator
      urlSlug
      explicit
    }
    audioUrl
    imageUrl
    publishedAt
    duration
    description
    explicit
    seasonNumber
    episodeNumber
    tags
  }
`;

export const CommentFragment = gql`
  fragment CommentContentFragment on Comment {
    id
    type
    name
    textComment {
      message
    }
    audioComment {
      url
      duration
    }
  }
  fragment CommentFragment on Comment {
    ...CommentContentFragment
  }
`;

export const CategoryFragment = gql`
  fragment CategoryFragment on Category {
    id
    nameEn
    nameZh
    parentCategory {
      id
      nameEn
      nameZh
    }
  }
`;
